import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath, Route, Switch } from 'react-router-dom';
import { SidebarPane, SidebarContent } from './Sidebar.styles';
import SidebarClose from './SidebarItems/SidebarClose/SidebarClose';
import SidebarProfile from './SidebarItems/SidebarProfile/SidebarProfile';
import SidebarAbout from './SidebarItems/SidebarAbout/SidebarAbout';
import SidebarSwitcher from './SidebarSwitcher/SidebarSwitcher';
import SidebarTreeDetail from './SidebarItems/SidebarTreeDetail/SidebarTreeDetail';
import { isValidPath, TOP_SIDEBAR_ROUTES, BOTTOM_SIDEBAR_ROUTES, ROUTE } from 'src/router/routes';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from 'src/context/UserContext';
import { UserDetail } from 'src/types/user';
import UserHelpers from 'src/helpers/user.helpers';
import { TreeData } from 'src/types/tree';
// import SidebarAddTree from './SidebarItems/SidebarAddTree/SidebarAddTree';

interface SidebarProps {
  allTreeData: TreeData;
  activeTreeCoords: L.LatLngTuple | null;
  clearSelectedTree: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ allTreeData, activeTreeCoords, clearSelectedTree }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [userDetail, setUserDetail] = useState<UserDetail | null | undefined>();
  const [sidebarPosition, setSidebarPosition] = useState<'top' | 'bottom'>('top');
  const { pathname: currentRoute } = useLocation();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchUserDetail = useCallback(async () => {
    const token = await getAccessTokenSilently();
    try {
      const fetchedUserDetail = await new UserHelpers().fetchUserDetails(token);
      setUserDetail(fetchedUserDetail);
    } catch (error) {
      setUserDetail(null);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const isValidPage = isValidPath(currentRoute);
    setExpanded(isValidPage);
    if (!isValidPage) return;

    if (TOP_SIDEBAR_ROUTES.some((route) => matchPath(currentRoute, route)))
      setSidebarPosition('top');
    if (BOTTOM_SIDEBAR_ROUTES.some((route) => matchPath(currentRoute, route)))
      setSidebarPosition('bottom');
  }, [currentRoute]);

  useEffect(() => {
    if (isAuthenticated) fetchUserDetail();
  }, [fetchUserDetail, isAuthenticated]);

  return (
    <UserContext.Provider value={{ userDetail, refreshUserDetail: fetchUserDetail }}>
      <SidebarPane
        showSidebar={isExpanded}
        isTreeDetail={Boolean(matchPath(currentRoute, ROUTE.TREE_DETAIL))}
        position={sidebarPosition}
      >
        <SidebarClose onClick={clearSelectedTree} />

        <SidebarContent>
          <Switch>
            <Route exact path="/profile">
              <SidebarProfile />
            </Route>
            <Route exact path="/about">
              <SidebarAbout />
            </Route>
            {/* <Route exact path="/stats">
            <SidebarUserStats />
          </Route> */}
            {/* <Route path="/addTree">
            <SidebarAddTree />
          </Route> */}
            <Route path="/tree/:treeId">
              <SidebarTreeDetail allTreeData={allTreeData} />
            </Route>
          </Switch>
        </SidebarContent>
      </SidebarPane>

      <SidebarSwitcher
        isSidebarShown={isExpanded}
        setSidebarShown={setExpanded}
        clearSelectedTree={clearSelectedTree}
      />
    </UserContext.Provider>
  );
};

export default Sidebar;
