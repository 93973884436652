import { device } from 'src/styles/mediaQueries';
import styled from 'styled-components';
import { Color } from 'src/styles/colors';

export const FormArea = styled.div`
  padding-top: 15px;

  form,
  .hide > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media ${device.mobileMax} {
      flex-direction: column;
    }
  }

  .hide {
    width: 100%;
  }

  .MuiAutocomplete-root,
  .MuiFormControl-root,
  .MuiWithoutAutocoplete {
    position: relative;
    @media ${device.tabletMin} {
      flex-basis: 46%;
    }
  }

  .MuiWithoutAutocoplete .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormControl-root {
    margin-bottom: 20px;
  }

  .MuiInput-input.Mui-disabled {
    background: #f7f7f7;
    cursor: not-allowed;
  }

  svg.helper {
    color: ${Color.GRAY_30};
    cursor: help;
    font-size: 15px;
    position: absolute;
    right: 5px;
  }
`;

export const Expand = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
  }
`;
