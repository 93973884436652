import { useContext } from 'react';

import { Flex, FlexColumn, FlexOuter } from './SidebarUserStats.styles';
import { CardDescription } from 'src/components/$common/Card/CardDescription';
import { CardHeadline } from 'src/components/$common/Card/CardHeadline';
import { UserContext } from 'src/context/UserContext';
import { Alert, Skeleton } from '@material-ui/lab';

const SidebarUserStats = () => {
  const { userDetail } = useContext(UserContext);

  const progressItems = [
    {
      icon: 'here',
      label: 'Počet zalití',
      id: 'timesSpend',
    },
    {
      icon: 'here',
      label: 'Množství použité vody (l)',
      id: 'liters',
    },
  ];

  return (
    <>
      {userDetail === undefined && <Skeleton height={48} />}
      {userDetail === null && (
        <Alert severity="error">Během nahrávání statistik došlo k chybě</Alert>
      )}
      {userDetail && (
        <Flex>
          {progressItems.map((item) => (
            <FlexOuter key={item.id}>
              {/*<Icon iconType={item.id === 'timesSpend' ? 'trees' : 'water'} />*/}
              <FlexColumn>
                <CardHeadline>
                  {item.id === 'liters' ? userDetail.totalWaterUsed : userDetail.numberOfWaterings}
                </CardHeadline>
                <CardDescription>{item.label}</CardDescription>
              </FlexColumn>
            </FlexOuter>
          ))}
        </Flex>
      )}
    </>
  );
};

export default SidebarUserStats;
