// get correct label for provided graph data
export const getLabel = (value: string | number) => {
  switch (value) {
    case 1:
      return 'Dnes';
    case 2:
      return 'Včera';
    default:
      return `Před ${value} dny`;
  }
};
