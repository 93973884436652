import styled from 'styled-components';
import LineIcon from '@material-ui/icons/Remove';

import { Color, markerColors } from '../../../../../styles/colors';

const ARROW_SIZE_PX = 32;
const ARROW_OFFSET_PX = ARROW_SIZE_PX / 2;

const markerColorScale = Object.values(markerColors).slice(1);

export const ScaleWrapper = styled.div`
  padding-top: 5px;
`;

export const Scale = styled.div`
  background-image: linear-gradient(90deg, ${markerColorScale.join(',')});
  flex: 1 1 0;
  height: 30px;
  margin-top: 10px;
  position: relative;
  width: auto;
`;

export const NeedArrow = styled(LineIcon)<{ pos: number }>`
  color: ${Color.GRAY_90};
  left: ${(props) => `calc(-${ARROW_OFFSET_PX}px + ${props.pos}%)`};
  opacity: 0.7;
  position: absolute;
  top: -1px;
  transform: rotate(90deg) scale(2);

  &.MuiSvgIcon-root {
    height: ${ARROW_SIZE_PX}px;
    width: ${ARROW_SIZE_PX}px;
  }
`;

export const ScaleLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;
