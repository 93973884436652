import { useEffect, useState } from 'react';
import { CircleMarker, Tooltip, Marker } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { icon, LatLngExpression, LatLngTuple } from 'leaflet';
import { decode } from 'ngeohash';
import { appColors, fullGradient } from 'src/styles/colors';
import { TreeDataWithWatering } from './CityLayer';
import { GeohashSegmentTreeMap, TreeData } from 'src/types/tree';

interface LayerProps {
  geohashesInView: string[];
  geohashSegmentMap: GeohashSegmentTreeMap;
  activeTreeCoords: LatLngExpression | null;
  treeBundleData: TreeData;
  selectTree: (position: LatLngTuple, zoom?: boolean) => void;
}

const TreeLayer = (treeLayerProps: LayerProps) => {
  const { geohashesInView, geohashSegmentMap, activeTreeCoords, selectTree } = treeLayerProps;
  const [renderedGeohashes, setRenderedGeohashes] = useState<string[]>([]);
  const [renderedMarkers, setRenderedMarkers] = useState<JSX.Element[]>([]);
  const history = useHistory();

  const selectActiveTree = async (treeId: string, treeCords: LatLngTuple) => {
    history.push(`/tree/${treeId}`);
    selectTree(treeCords);
  };

  const renderMarkers = () => {
    // Filter out geohashes for which data is rendered already
    const geohashesToRender = geohashesInView.filter((hash) => !renderedGeohashes.includes(hash));

    // console.log(
    //   'hashes in view',
    //   geohashesInView.length,
    //   'rendered',
    //   renderedGeohashes.length,
    //   'to render',
    //   geohashesToRender.length
    // );

    // Reduce the geohash store to just trees that are currently in view

    // const treesInView = Object.values(treeBundleData).filter((tree) =>
    //   geohashesToRender.includes(tree.geohash.substring(0, geohashPrecision))
    // );

    const treesInView = geohashesToRender
      .map((hash) => geohashSegmentMap[hash])
      .filter((maybeTrees) => !!maybeTrees)
      .flat();

    const newMarkers = treesInView.map((tree: TreeDataWithWatering) => {
      const decoded = decode(tree.geohash);
      const treeLatLng: LatLngTuple = [decoded.latitude, decoded.longitude];
      const wateringHoleIndicator = tree.hasWateringHole ? (
        <CircleMarker center={treeLatLng} radius={2} color={appColors.blue} />
      ) : null;

      const newIcon = icon({ iconUrl: '/tree-adoption.png', iconSize: [32, 32] });

      const wateringNeed = tree.wateringNeed - 1; // TODO: is this correct? fullGradient array has only 126 items and this can generate values to 127

      if (tree.adoptedTreeName) {
        return (
          <Marker
            center={treeLatLng}
            position={treeLatLng}
            icon={newIcon}
            onClick={() => selectActiveTree(tree.geohash, treeLatLng)}
          >
            <CircleMarker
              key={`tree-${tree.geohash}`}
              center={treeLatLng}
              radius={13}
              position={treeLatLng}
              color={fullGradient[wateringNeed].toHexString()}
            >
              <Tooltip direction="top" permanent className="testclass">
                {tree.adoptedTreeName}
              </Tooltip>
            </CircleMarker>
          </Marker>
        );
      }

      return (
        <CircleMarker
          key={`tree-${tree.geohash}`}
          center={treeLatLng}
          radius={7}
          position={treeLatLng}
          color={fullGradient[wateringNeed].toHexString()}
          onClick={() => selectActiveTree(tree.geohash, treeLatLng)}
        >
          {wateringHoleIndicator}
        </CircleMarker>
      );
    });
    const updatedMarkers = renderedMarkers.concat(newMarkers);
    const updatedGeohashes = renderedGeohashes.concat(geohashesToRender);
    setRenderedMarkers(updatedMarkers);
    setRenderedGeohashes(updatedGeohashes);
    // Debugging information
    // console.info(`Rendering ${newMarkers.length} new trees (${updatedMarkers.length} total) for ${geohashesToRender.length} new geohashes (${updatedGeohashes.length} total)`);
  };

  useEffect(() => {
    renderMarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geohashesInView]);

  return (
    <>
      {renderedMarkers}

      {activeTreeCoords && (
        <CircleMarker center={activeTreeCoords} radius={10} color={appColors.blue} />
      )}
    </>
  );
};

export default TreeLayer;
