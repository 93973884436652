import styled from 'styled-components';

interface StyledButtonProps {
  isActive?: boolean;
  size?: string;
}
export default styled.button<StyledButtonProps>`
  width: 36px;
  height: 36px;
  background-color: white;
  color: #2c303b;
  border: 1px solid #2c303b;
  transition: background-color 0.3s;
  will-change: background-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
  &:hover {
    background-color: #2c303b;
    color: white;
  }
`;
