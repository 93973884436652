import { FC, useContext, useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import { SidebarTreePreview } from '../../SidebarTreePreview/SidebarTreePreview';
import UserHelpers from '../../../../../helpers/user.helpers';
import { AssociatedTreeDetail, UserTreeAssociation } from '../../../../../types/tree';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { UserContext } from 'src/context/UserContext';

export const SidebarMyTrees: FC = () => {
  const [favoriteTrees, setFavoriteTrees] = useState<AssociatedTreeDetail[] | null>();
  const [wateredTrees, setWateredTrees] = useState<AssociatedTreeDetail[] | null>();
  const [modifiedTrees, setModifiedTrees] = useState<AssociatedTreeDetail[] | null>();
  const [reservedTrees, setReservedTrees] = useState<AssociatedTreeDetail[] | null>();
  const [adoptedTrees, setAdoptedTrees] = useState<AssociatedTreeDetail[] | null>();
  const [, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const { userDetail, refreshUserDetail } = useContext(UserContext);

  const failedFetchingTrees =
    favoriteTrees === null ||
    wateredTrees === null ||
    modifiedTrees === null ||
    reservedTrees === null ||
    adoptedTrees === null;

  // const adoptedAndReservedTrees =
  //   adoptedTrees && reservedTrees ? [...adoptedTrees, ...reservedTrees] : [];

  const unfavoriteTree = async (treeId: string) => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      await new UserHelpers().deleteFavoriteTree(treeId, token);
      setFavoriteTrees(favoriteTrees!.filter((tree) => tree.treeId !== treeId));
      refreshUserDetail();
      enqueueSnackbar(`Strom byl odebrán z oblíbených stromů`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Chyba při odebírání stromu z oblíbených, zkuste to prosím později.', {
        variant: 'error',
      });
    }

    setLoading(false);
  };

  // TODO: Check trees in the list of associated trees really exist
  const renderListOfTrees = (treeType: UserTreeAssociation, trees?: AssociatedTreeDetail[]) => {
    return (
      <List>
        {trees?.map((tree) => (
          <SidebarTreePreview
            key={tree.treeId}
            tree={tree}
            treeType={treeType}
            deleteClicked={() => unfavoriteTree(tree.treeId)}
          />
        ))}
        {trees?.length === 0 && (
          <Alert severity={'info'}>V této sekci zatím nemáte žádné stromy</Alert>
        )}
        {trees === undefined && (
          <>
            <Skeleton height={52} />
            <Skeleton height={52} />
            <Skeleton height={52} />
          </>
        )}
      </List>
    );
  };

  useEffect(() => {
    function getMyTrees() {
      if (userDetail === undefined) return;
      if (userDetail === null) {
        setFavoriteTrees(null);
        setWateredTrees(null);
        setModifiedTrees(null);
      }

      const userAssociatedTreeDetails = userDetail?.associatedTrees ?? [];
      const userFavoriteTrees = userAssociatedTreeDetails.filter((tree) =>
        tree.associations.includes(UserTreeAssociation.FAVORITE)
      );
      const userModifiedTrees = userAssociatedTreeDetails.filter((tree) =>
        tree.associations.includes(UserTreeAssociation.MODIFIED)
      );
      const userReservedTrees = userAssociatedTreeDetails.filter((tree) =>
        tree.associations.includes(UserTreeAssociation.RESERVED)
      );
      const userAdoptedTrees = userAssociatedTreeDetails.filter((tree) =>
        tree.associations.includes(UserTreeAssociation.ADOPTED)
      );
      const userWateredTrees = userAssociatedTreeDetails.filter(
        (tree) =>
          tree.associations.includes(UserTreeAssociation.WATERED)
      );

      setFavoriteTrees(userFavoriteTrees);
      setWateredTrees(userWateredTrees);
      setModifiedTrees(userModifiedTrees);
      setReservedTrees(userReservedTrees);
      setAdoptedTrees(userAdoptedTrees);
    }
    getMyTrees();
  }, [userDetail]);

  return (
    <>
      <StyledHeading>Oblíbené stromy</StyledHeading>
      {/* TODO: Better typing */}
      {!failedFetchingTrees && renderListOfTrees(UserTreeAssociation.FAVORITE, favoriteTrees!)}
      {failedFetchingTrees && <Alert severity="error">Během načítání stromů došlo k chybě</Alert>}

      <StyledHeading>Zalité stromy</StyledHeading>
      {/* TODO: Better typing */}
      {!failedFetchingTrees && renderListOfTrees(UserTreeAssociation.WATERED, wateredTrees!)}
      {failedFetchingTrees && <Alert severity="error">Během načítání stromů došlo k chybě</Alert>}

      <StyledHeading>Upravené stromy</StyledHeading>
      {/* TODO: Better typing */}
      {!failedFetchingTrees && renderListOfTrees(UserTreeAssociation.MODIFIED, modifiedTrees!)}
      {failedFetchingTrees && <Alert severity="error">Během načítání stromů došlo k chybě</Alert>}

      <StyledHeading>Adoptované stromy</StyledHeading>
      {/* TODO: Better typing */}
      {!failedFetchingTrees &&
        !!reservedTrees?.length &&
        renderListOfTrees(UserTreeAssociation.RESERVED, reservedTrees)}
      {!failedFetchingTrees &&
        !!adoptedTrees?.length &&
        renderListOfTrees(UserTreeAssociation.ADOPTED, adoptedTrees)}
      {!reservedTrees?.length && !adoptedTrees?.length && (
        <Alert severity={'info'}>V této sekci zatím nemáte žádné stromy</Alert>
      )}

      {failedFetchingTrees && <Alert severity="error">Během načítání stromů došlo k chybě</Alert>}
    </>
  );
};

const StyledHeading = styled.h4`
  margin-bottom: 0px;
`;
