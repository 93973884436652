import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryParams = Object.fromEntries(searchParams.entries());

  return queryParams;
};

export default useQuery;
