import { useAuth0 } from '@auth0/auth0-react';
import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';

const apiUrl = EnvHelpers.getVar(EnvVar.REACT_APP_API_ENDPOINT);

/**
 * Custom hook used to communicate with API
 */
const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const queryApiWithBody = async (
    resourceUri: string,
    body: { [key: string]: any },
    method: 'POST' | 'PUT'
  ): Promise<[Response | null, any]> => {
    const fullUri = `${apiUrl}${resourceUri}`;
    try {
      const maybeToken = await getAccessTokenSilently();

      let headers: HeadersInit = {
        'Content-Type': 'application/json',
      };
      if (maybeToken) headers = { ...headers, Authorization: `Bearer ${maybeToken}` };

      const requestOptions = {
        method,
        headers,
        body: JSON.stringify(body),
      };

      const response = await fetch(fullUri, requestOptions);

      if (![200, 201, 204].includes(response.status)) {
        throw new Error(`Request to '${fullUri}' has failed`);
      }

      const responseData = response.json();
      return [response, responseData];
    } catch (err) {
      console.error(err);
      return [null, null];
      // return [response, null];
    }
  };

  const put = (uri: string, body: { [key: string]: any }) => queryApiWithBody(uri, body, 'PUT');
  const post = (uri: string, body: { [key: string]: any }) => queryApiWithBody(uri, body, 'POST');

  return { put, post };
};

export default useApi;
