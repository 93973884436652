import { FC, useContext } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { AssociatedTreeDetail, UserTreeAssociation } from '../../../../types/tree';
import { Skeleton } from '@material-ui/lab';
import TreeHelpers from '../../../../helpers/tree.helpers';
import DeleteIcon from '@material-ui/icons/Delete';
import NatureIcon from '@material-ui/icons/Nature';
import { MapContext } from 'src/context/MapContext';
import {
  Content,
  TreeInfo,
  TreeDetailLink,
  TreeName,
  TaxonName,
  LocationIcon,
  DeleteButton,
  Location,
  ReservedTree,
} from './SidebarTreePreview.styles';

type Props = {
  tree: AssociatedTreeDetail;
  treeType: UserTreeAssociation;
  deleteClicked: () => void;
};
export const SidebarTreePreview: FC<Props> = ({ tree, treeType, deleteClicked }) => {
  const treeHelpers = new TreeHelpers();
  const { treeData: allTreeData, selectTree } = useContext(MapContext);
  const treeData = allTreeData[tree.treeId];
  const wateringNeedColor = treeHelpers.calculateWateringNeedColor(treeData!.wateringNeed);
  const isFavoriteTree = treeType === UserTreeAssociation.FAVORITE;

  const centerMapOnSelectedTree = () => {
    selectTree!(treeHelpers.getTreePosition(treeData!), true);
  };

  if (tree) {
    const treeName =
      treeType === UserTreeAssociation.FAVORITE
        ? tree.treeName
        : `Strom v ${tree.districtName}, ${tree.cityName}`;

    return (
      <Content>
        <TreeInfo wateringColor={wateringNeedColor}>
          <TreeDetailLink to={`/tree/${tree.treeId}`} onClick={centerMapOnSelectedTree}>
            <TreeName>{treeName}</TreeName>
            {tree.taxonCzech && (
              <TaxonName>
                <NatureIcon fontSize={'small'} color={'disabled'} />
                <span>{tree.taxonCzech}</span>
              </TaxonName>
            )}

            {isFavoriteTree && (
              <Grid container alignItems={'center'}>
                <LocationIcon fontSize={'small'} color={'disabled'} />
                <Location>{`${tree.districtName}, ${tree.cityName}`}</Location>
              </Grid>
            )}
            {treeType === UserTreeAssociation.RESERVED && (
              <ReservedTree>Rezevován - čeká na potvrzení platby</ReservedTree>
            )}
          </TreeDetailLink>
          {isFavoriteTree && (
            <Tooltip title={'Odebrat z oblíbených'}>
              <DeleteButton onClick={deleteClicked}>
                <DeleteIcon />
              </DeleteButton>
            </Tooltip>
          )}
        </TreeInfo>
      </Content>
    );
  } else {
    return <Skeleton height={52} />;
  }
};
