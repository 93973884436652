import React from 'react';
import { ViewType } from 'src/components/Map/Map';
import { TreeData } from 'src/types/tree';

interface MapContextProps {
  treeData: TreeData;
  showIndexLayer: boolean;
  setShowIndexLayer: any;
  currentViewType: ViewType;

  centerIntoView: (
    coords: L.LatLngTuple,
    zoomLevel?: number,
    duration?: number,
    offsetCorrection?: boolean
  ) => void;
  selectTree: (treePosition: L.LatLngTuple, zoom?: boolean) => void;
  clearSelectedTree: () => void;
}

export const MapContext = React.createContext<MapContextProps>({} as MapContextProps);
