import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EditIcon from '@material-ui/icons/Edit';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import EcoIcon from '@material-ui/icons/Eco';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import StreetviewIcon from '@material-ui/icons/Streetview';
import ToysIcon from '@material-ui/icons/Toys';
import SpaIcon from '@material-ui/icons/Spa';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NoteIcon from '@material-ui/icons/Note';
import HeightIcon from '@material-ui/icons/Height';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

import { EditTitle, SidebarSubtitle, WateringTitle } from '../SidebarItems.styles';
import { TreeData, TreeDetail, TreeDetailsOptional, UserTreeAssociation } from 'src/types/tree';
import TreeHelpers from 'src/helpers/tree.helpers';
import TreeInfoRow from './TreeInfoRow/TreeInfoRow';
import WateringScale from './WateringScale/WateringScale';
import TreeWatering from './TreeWatering/TreeWatering';
import Loader from 'src/components/Loader/Loader';
import {
  AdoptedTreeSection,
  AdoptionImage,
  SidebarAdoptedTitle,
  SidebarHeader,
  SidebarTreeName,
  SidebarTreeNameSection,
  StyledDetailLink,
} from './SidebarTreeDetail.styles';
import { SidebarFavoriteTree } from '../SidebarFavoriteTree/SidebarFavoriteTree/SidebarFavoriteTree';
import { FavoriteTreeModal } from '../SidebarFavoriteTree/FavoriteTreeModal';
import { UserContext } from 'src/context/UserContext';
import TreeWateringRecords from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeWateringRecords/TreeWateringRecords';
import Precipitation from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/Precipitation/Precipitation';
import { NonAccessModal } from './TreeWatering/NonAccessModal';
import { EditModal } from './EditModal/EditModal';
import AdoptionModal from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/AdoptionModal/AdoptionModal';
import { AdoptionStatus } from 'src/types/adoption.type';
import { Tooltip } from '@material-ui/core';
import SidebarAdoptionFields from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/SidebarAdoptionFields/SidebarAdoptionFields';

import { ROUTE } from 'src/router/routes';

interface Props {
  allTreeData?: TreeData;
}

const treeHelpers = new TreeHelpers();

const identityFunction = (val: string | number) => {
  if (isNaN(Number(val))) {
    return val || '-';
  }
  return val || 0;
};
const taxonFunction = (taxon: string | number, shouldLinkToWikipedia: boolean) => {
  if (typeof taxon !== 'string') {
    return '';
  }
  const informationLink = shouldLinkToWikipedia
    ? `https://cs.wikipedia.org/wiki/${taxon.toLowerCase().replace(' ', '_')}`
    : `https://www.google.com/search?q=${taxon.toLowerCase()}`;

  return (
    <StyledDetailLink href={informationLink} target="_blank">
      {taxon}
    </StyledDetailLink>
  );
};

type AttributeIcon = JSX.Element;
type AttributeName = string;
type AttributeTransformFunction = (initialValue: string | number) => string | number | JSX.Element;
type OptionalRowMap = {
  [attributeName in keyof Omit<
    TreeDetailsOptional,
    'treeStory' | 'treeImageUrl' | 'wateringPriorityAdjustment'
  >]: [AttributeIcon, AttributeName, AttributeTransformFunction];
};

const optionalAttributeMap: OptionalRowMap = {
  taxonCzech: [<TextFieldsIcon />, 'Český název', (taxon) => taxonFunction(taxon, true)],
  taxonLatin: [<TextFieldsIcon />, 'Latinský název', (taxon) => taxonFunction(taxon, false)],
  diameterTrunk: [<ThreeSixtyIcon />, 'Průměr kmene (cm)', identityFunction],
  diameterCrown: [<ThreeSixtyIcon />, 'Průměr koruny (m)', identityFunction],
  height: [<HeightIcon />, 'Výška (m)', identityFunction],
  heightBelowCrown: [<SquareFootIcon />, 'Výška nasazení koruny (m)', identityFunction],
  crownShape: [<StreetviewIcon />, 'Tvar koruny', identityFunction],
  growthConditions: [<EcoIcon />, 'Růstové podmínky', identityFunction],
  vitality: [<SpaIcon />, 'Vitalita', identityFunction],
  physiologicalAge: [<EcoIcon />, 'Fyziologické stáří', identityFunction],
  plantingYear: [<LocalFloristIcon />, 'Rok výsadby', identityFunction],
  plantingSeedingYear: [<LocalFloristIcon />, 'Rok vyklíčení', identityFunction],
  health: [<LocalHospitalIcon />, 'Zdravotní stav', identityFunction],
  perspektive: [<NaturePeopleIcon />, 'Perspektiva', identityFunction],
  stability: [<ToysIcon />, 'Stalibita', identityFunction],
  hasWateringHole: [<LocalDrinkIcon />, 'Má zalévací místo', identityFunction],
  hydrogel: [<ScatterPlotIcon />, 'Hydrogel', identityFunction],
  noteToOwner: [<NoteIcon />, 'Poznámka pro vlastníka', identityFunction],
  detailedLocation: [<LocationOnIcon />, 'Detailní pozice', identityFunction],
  latitude: [<LocationOnIcon />, 'Zeměp. šířka', identityFunction],
  longitude: [<LocationOnIcon />, 'Zeměp. délka', identityFunction],
  wateringPriority: [<LocalDrinkIcon />, 'Priorita zalévání', identityFunction],
  noteFromOwner: [<NoteIcon />, 'Poznámka vlastníka', identityFunction],
  managedBy: [<PermContactCalendarIcon />, 'Správce', identityFunction],
};

const SidebarTreeDetail: React.FC<Props> = ({ allTreeData }) => {
  const [treeDetail, setTreeDetail] = useState<TreeDetail | null>();
  const [isFetching, setFetching] = useState(true);
  const [addFavoriteTreeModalOpen, setAddFavoriteTreeModalOpen] = useState(false);
  const [adoptionWallOpen, setAdoptionWallOpen] = useState(false);
  const [nonAccessModalOpen, setNonAccessModalOpen] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [favoriteTreeName, setFavoriteTreeName] = useState<string | undefined>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [adoptionModalOpen, setAdoptionModalOpen] = useState(false);
  const { treeId } = useParams<{ treeId: string }>();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { userDetail, refreshUserDetail } = useContext(UserContext);
  const [isTreeReserved, setIsTreeReserved] = useState(false);

  const renderOptionalInfoRow = (
    attribute: keyof TreeDetailsOptional,
    value: string | number | undefined
  ): JSX.Element | null => {
    if (
      attribute === 'treeStory' ||
      attribute === 'treeImageUrl' ||
      attribute === 'wateringPriorityAdjustment'
    ) {
      return null;
    }
    if (!optionalAttributeMap[attribute] || !value) return null;

    const [Icon, attributeName, valueFunction] = optionalAttributeMap[attribute]!;
    const transformedValue = valueFunction(value!);

    return (
      <TreeInfoRow icon={Icon} attribute={attributeName} value={transformedValue} oneRow={true} />
    );
  };

  const fetchTreeData = async () => {
    const maybeData = await treeHelpers.fetchTreeDetail(treeId);
    setTreeDetail(maybeData);
    setFetching(false);
  };

  const addTreeToMyTrees = () => {
    setAddFavoriteTreeModalOpen(false);
    refreshUserDetail();
  };

  useEffect(() => {
    if (treeId) fetchTreeData();

    return () => {
      setTreeDetail(undefined);
      setFetching(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeId, getAccessTokenSilently]);

  useEffect(() => {
    function isTreeFavorite() {
      if (!userDetail) return;

      const maybeTreeAssociation = userDetail.associatedTrees.find(
        (tree) => tree.treeId === treeId
      );

      if (!maybeTreeAssociation) {
        setFavorite(false);
        setFavoriteTreeName(undefined);
        return;
      }

      setFavorite(maybeTreeAssociation.associations.includes(UserTreeAssociation.FAVORITE));
      setFavoriteTreeName(maybeTreeAssociation.treeName);
    }
    isTreeFavorite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail, treeId]);

  const districtName = treeDetail?.districtName;
  const cityName = treeDetail?.cityName;
  const treeDetailLocation = districtName === cityName ? cityName : `${districtName}, ${cityName}`;
  const treeTaxon = treeDetail?.taxonCzech ?? treeDetail?.taxonLatin;
  const isTreeAdopted =
    treeDetail &&
    treeDetail.treeAdoption &&
    treeDetail.treeAdoption.status === AdoptionStatus.ADOPTED;

  const canAdoptTree = !isTreeAdopted && !isTreeReserved;

  useEffect(() => {
    const isTreeReserved =
      !!treeDetail?.treeAdoption && treeDetail.treeAdoption.status === AdoptionStatus.RESERVED;
    setIsTreeReserved(isTreeReserved);
  }, [isTreeAdopted, treeDetail?.treeAdoption]);

  const adoptionMessage = treeDetail?.treeAdoption?.parentNickname
    ? `Adoptoval/a ${treeDetail.treeAdoption?.parentNickname}`
    : 'Adoptován';
  const adoptionTooltip = !canAdoptTree
    ? 'Tento strom je už adoptován / rezervován, vyber si prosím jiný strom'
    : 'Adoptuj si mě';

  const onAdoptionClick = () => {
    if (!isAuthenticated) return setAdoptionWallOpen(true);
    return setAdoptionModalOpen(true);
  };

  return (
    <>
      {isFetching && <Loader message="Nahrávám detail stromu..." />}
      {!isFetching && treeDetail && allTreeData && (
        <>
          <SidebarHeader>
            <AdoptedTreeSection>
              <Tooltip title={adoptionTooltip}>
                <AdoptionImage
                  src="/tree-adoption.png"
                  onClick={canAdoptTree ? onAdoptionClick : () => {}}
                  canAdopt={canAdoptTree}
                />
              </Tooltip>
            </AdoptedTreeSection>
            <SidebarTreeNameSection>
              <SidebarTreeName>
                {isAuthenticated && (
                  <SidebarFavoriteTree
                    treeId={treeId}
                    isFavorite={favorite}
                    showAddFavoriteDialog={() => setAddFavoriteTreeModalOpen(true)}
                  />
                )}
                <h3>{favoriteTreeName || treeTaxon || 'Strom'}</h3>
              </SidebarTreeName>
              <SidebarAdoptedTitle>{isTreeAdopted && adoptionMessage}</SidebarAdoptedTitle>
            </SidebarTreeNameSection>

            <div style={{ width: '32px' }} />
          </SidebarHeader>

          <SidebarAdoptionFields
            treeDetail={treeDetail}
            openAdoptionModal={() => setAdoptionModalOpen(true)}
            isTreeAdopted={!!isTreeAdopted}
            isTreeReserved={isTreeReserved}
            updateTreeData={fetchTreeData}
          />

          <WateringScale wateringNeed={allTreeData[treeDetail.id].wateringNeed} />

          <WateringTitle>Vyberte množství, kterým jste zalili strom:</WateringTitle>

          <TreeWatering
            treeId={treeDetail.id}
            isFavorite={favorite}
            showFavoriteTreeModal={() => setAddFavoriteTreeModalOpen(true)}
            showNonAccessModal={() => setNonAccessModalOpen(true)}
          />

          <TreeWateringRecords lastWaterings={treeDetail.lastWaterings} />

          <Precipitation dailyPrecipitationSums={treeDetail.dailyPrecipitationSums} />

          <EditTitle>
            <SidebarSubtitle>Informace o stromu</SidebarSubtitle>
            <EditIcon
              className="absolute-svg"
              onClick={() =>
                isAuthenticated ? setEditModalOpen(true) : setNonAccessModalOpen(true)
              }
            />
          </EditTitle>

          <EditModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            treeData={treeDetail}
            updateTreeData={fetchTreeData}
          />

          <AdoptionModal
            open={adoptionModalOpen}
            onClose={() => setAdoptionModalOpen(false)}
            treeDetail={treeDetail}
            updateTreeData={fetchTreeData}
          />

          {/* Hardcoded rendering of optional properties as dynamic rendering order keeps changing
          TODO: Ensure consistend rendering order for optional properties and remove this bit */}
          {renderOptionalInfoRow('taxonCzech', treeDetail.taxonCzech)}
          {renderOptionalInfoRow('taxonLatin', treeDetail.taxonLatin)}
          {renderOptionalInfoRow('diameterTrunk', treeDetail.diameterTrunk)}
          {renderOptionalInfoRow('diameterCrown', treeDetail.diameterCrown)}
          {renderOptionalInfoRow('height', treeDetail.height)}
          {renderOptionalInfoRow('heightBelowCrown', treeDetail.heightBelowCrown)}
          {renderOptionalInfoRow('crownShape', treeDetail.crownShape)}
          {renderOptionalInfoRow('growthConditions', treeDetail.growthConditions)}
          {renderOptionalInfoRow('vitality', treeDetail.vitality)}
          {renderOptionalInfoRow('physiologicalAge', treeDetail.physiologicalAge)}
          {renderOptionalInfoRow('plantingYear', treeDetail.plantingYear)}
          {renderOptionalInfoRow('plantingSeedingYear', treeDetail.plantingSeedingYear)}
          {renderOptionalInfoRow('health', treeDetail.health)}
          {renderOptionalInfoRow('perspektive', treeDetail.perspektive)}
          {renderOptionalInfoRow('stability', treeDetail.stability)}
          {renderOptionalInfoRow('hasWateringHole', treeDetail.hasWateringHole)}
          {renderOptionalInfoRow('hydrogel', treeDetail.hydrogel)}

          {/* // TODO: Finish later */}
          {/* <SidebarHistory>Historie změn</SidebarHistory> */}

          <SidebarSubtitle>Ostatní informace</SidebarSubtitle>

          <TreeInfoRow
            icon={<LocationCityIcon />}
            attribute="Umístění"
            value={treeDetailLocation}
            oneRow
          />
          {/* TODO: Only the owner can see the treeDetail.noteToOwner */}
          {renderOptionalInfoRow('noteToOwner', treeDetail.noteToOwner)}
          {renderOptionalInfoRow('noteFromOwner', treeDetail.noteFromOwner)}
          {renderOptionalInfoRow('latitude', treeDetail.latitude)}
          {renderOptionalInfoRow('longitude', treeDetail.longitude)}
          {renderOptionalInfoRow('wateringPriority', treeDetail.wateringPriority)}
          {renderOptionalInfoRow('managedBy', treeDetail.managedBy)}

          <NonAccessModal
            title={'Editace stromu'}
            message={
              'Pro úpravu informací o stromu se musíš přihlásit. Pokud ještě nemáš účet, můžeš si jej zdarma vytvořit. Díky tomu získáš přístup také k dalším funkcím aplikace.'
            }
            open={nonAccessModalOpen}
            onClose={() => setNonAccessModalOpen(false)}
            actionUrl={ROUTE.PROFILE}
            actionLabel="Přihlásit se / Zaregistrovat"
            cancelLabel="Zrušit"
          />

          <NonAccessModal
            title={'Adopce stromu'}
            message={
              'Pro adopci stromu se musíš přihlásit. Pokud ještě nemáš účet, můžeš si jej zdarma vytvořit. Díky tomu získáš přístup také k dalším funkcím aplikace.'
            }
            open={adoptionWallOpen}
            onClose={() => setAdoptionWallOpen(false)}
            actionLabel="Přihlásit se / Zaregistrovat"
            cancelLabel="Zrušit"
            actionUrl={ROUTE.PROFILE}
          />

          <FavoriteTreeModal
            open={addFavoriteTreeModalOpen}
            treeId={treeId}
            treeAdded={addTreeToMyTrees}
            onClose={() => setAddFavoriteTreeModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default SidebarTreeDetail;
