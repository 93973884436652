import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { StyledLink } from './SidebarClose.styles';
import RoundButton from 'src/components/RoundButton/RoundButton';

interface Props {
  onClick: () => void;
}

const SidebarClose: React.FC<Props> = ({ onClick }) => {
  return (
    <StyledLink onClick={onClick} to={{ pathname: '/', search: '' }}>
      <RoundButton>
        <CloseIcon />
      </RoundButton>
    </StyledLink>
  );
};

export default SidebarClose;
