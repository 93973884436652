import { numberBetweenOrEmptyString } from 'src/schema/common.schema';
import { z } from 'zod';

const currentYear = new Date().getFullYear();
const blankOption = z.enum(['neuvedeno']).transform(() => null);

export const TreeCrownShapeEnum = z.enum([
  'kuželovitá',
  'sloupovitá',
  'zaoblená',
  'kulovitá',
  'jiná',
]);
export const TreeGrowthConditionEnum = z.enum(['neovlivněné', 'dobré', 'zhoršené', 'extrémní']);
export const TreeVitalityEnum = z.enum([
  'výborná až mírně snížená',
  'zřetelně snížená',
  'výrazně snížená',
  'zbytková',
  'suchý strom',
]);
export const TreeAgeEnum = z.enum([
  'mladý strom ve fázi aklimatizace',
  'aklimatizovaný mladý strom',
  'dospívající strom',
  'dospělý strom',
  'senescentní strom',
]);
export const TreeHealthEnum = z.enum([
  'výborný až dobrý',
  'zhoršený',
  'výrazně zhoršený',
  'silně narušený',
]);
export const TreePerspectiveEnum = z.enum([
  'dlouhodobě perspektivní',
  'krátkodobě perspektivní',
  'neperspektivní',
]);
export const TreeStabilityEnum = z.enum([
  'výborná',
  'dobrá',
  'zhoršená',
  'výrazně zhoršená',
  'havarijní strom',
]);
export const YesNoEnum = z.enum(['Ano', 'Ne']);

export const TreeWateringPriorityAdjustmentEnum = z.enum(['nízká', 'střední', 'vysoká']);

export const updateAdoptedTreeSchema = z.object({
  treeAdoption: z.object({
    parentNickname: z.string().max(50),
  }),
  treeStory: z.string().max(200).optional(),
  treeImageUrl: z.string().optional(),
  wateringPriorityAdjustment: TreeWateringPriorityAdjustmentEnum,
});

export const updateTreeSchema = z
  .object({
    taxonId: z.union([blankOption, z.string().optional()]),
    taxonCzech: z.union([blankOption, z.string().optional()]),
    taxonLatin: z.union([blankOption, z.string().optional()]),
    diameterTrunk: numberBetweenOrEmptyString(0, 1100, 'Průměr musí být v rozmezí 0 - 1100 cm'),
    diameterCrown: numberBetweenOrEmptyString(0, 50, 'Průměr musí být v rozmezí 0 - 50 cm'),
    height: numberBetweenOrEmptyString(0, 115, 'Průměr musí být v rozmezí 0 - 115 m'),
    heightBelowCrown: numberBetweenOrEmptyString(0, 50, 'Průměr musí být v rozmezí 0 - 50 m'),
    plantingYear: numberBetweenOrEmptyString(
      0 - 1,
      currentYear + 1,
      'Rok musí být v rozmezí 0 - současný rok'
    ),
    plantingSeedingYear: numberBetweenOrEmptyString(
      0 - 1,
      currentYear + 1,
      'Rok musí být v rozmezí 0 - současný rok'
    ),
    wateringPriority: numberBetweenOrEmptyString(-1, 8, 'Priorita zalévání musí být v rozmezí 0-7'),

    // TODO: Create a condition for latitude and longitude
    latitude: z.number().optional(),
    longitude: z.number().optional(),

    noteToOwner: z.string().max(250, { message: 'Maximální počet znaků je 250' }).optional(),
    noteFromOwner: z.string().max(250, { message: 'Maximální počet znaků je 250' }).optional(),

    crownShape: z.union([TreeCrownShapeEnum.optional(), z.string().transform(() => null)]),
    growthConditions: z.union([
      TreeGrowthConditionEnum.optional(),
      z.string().transform(() => null),
    ]),
    vitality: z.union([TreeVitalityEnum.optional(), blankOption]),
    physiologicalAge: z.union([TreeAgeEnum.optional(), blankOption]),
    health: z.union([TreeHealthEnum.optional(), blankOption]),
    perspektive: z.union([TreePerspectiveEnum.optional(), blankOption]),
    stability: z.union([TreeStabilityEnum.optional(), blankOption]),
    hasWateringHole: z.union([YesNoEnum.optional(), blankOption]),
    hydrogel: z.union([YesNoEnum.optional(), blankOption]),
  })
  .refine(
    (data) => {
      if (!!data.heightBelowCrown && !data.height) {
        return false;
      }
      return true;
    },
    {
      message: 'Výška stromu pod korunou vyžaduje zadanou celkovou výšku stromu',
      path: ['heightBelowCrown'],
    }
  )
  .refine(
    (data) => {
      if (data.heightBelowCrown && data.height && data.heightBelowCrown >= data.height) {
        return false;
      }
      return true;
    },
    {
      message: 'Výška stromu pod korunou musí být menší než celková výška stromu',
      path: ['heightBelowCrown'],
    }
  )
  .refine(
    (data) => {
      if (
        data.plantingYear &&
        data.plantingSeedingYear &&
        data.plantingSeedingYear > data.plantingYear
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Rok vyklíčení musí být menší než rok výsadby',
      path: ['plantingSeedingYear'],
    }
  );

export type TreeFormData = Exclude<z.infer<typeof updateTreeSchema>, 'taxonId'>;
export type TreeFormAdoptionData = z.infer<typeof updateAdoptedTreeSchema>;
