import React from 'react';
import { XAxis, YAxis, AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';
import { getLabel } from './helpers';
import { tooltipStyles, wrapperStyles, DescriptionText } from './PrecipitationGraph.styles';
import { Color, colors } from 'src/styles/colors';

interface PrecipitationGraphProps {
  precipitationSums: number[];
}

const PrecipitationGraph: React.FunctionComponent<PrecipitationGraphProps> = ({
  precipitationSums,
}) => {
  const graphData = precipitationSums
    .map((precipitationOnDay, i) => ({
      day: i + 1,
      precipitation: precipitationOnDay,
    }))
    .reverse();

  return (
    <>
      <DescriptionText>Denní srážky za uplynulých 30 dní (mm)</DescriptionText>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={graphData}
          reverseStackOrder
          margin={{ top: 10, right: 0, left: 0, bottom: 10 }}
        >
          <XAxis tick={{ fontSize: 11 }} stroke={Color.GRAY_70} dataKey="day" interval={1} />
          <YAxis width={30} tick={{ fontSize: 11 }} stroke={Color.GRAY_70} />
          <Area
            type="monotone"
            dataKey="precipitation"
            stroke={colors.blue}
            dot={false}
            fill={colors.blue}
            fillOpacity={0.6}
          />
          <Tooltip
            filterNull={false}
            formatter={(value) => [value === null ? 'žádná data' : `${value} mm`, 'Srážky']}
            labelFormatter={(value) => getLabel(value)}
            contentStyle={tooltipStyles}
            wrapperStyle={wrapperStyles}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default PrecipitationGraph;
