import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CookieConsent from 'react-cookie-consent';

// Leaflet webpack fix
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { theme } from './styles/appTheme';
import { Auth0Provider } from '@auth0/auth0-react';
import MapPage from './components/Map/Map';
import { AUTH_REDIRECT_URL } from 'src/router/routes';
import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={EnvHelpers.getVar(EnvVar.REACT_APP_AUTH0_DOMAIN)}
          clientId={EnvHelpers.getVar(EnvVar.REACT_APP_AUTH0_CLIENT_ID)}
          redirectUri={AUTH_REDIRECT_URL}
          audience={EnvHelpers.getVar(EnvVar.REACT_APP_AUTH0_AUDIENCE)}
        >
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <StylesProvider injectFirst>
              <MapPage />
            </StylesProvider>
          </SnackbarProvider>
        </Auth0Provider>
      </ThemeProvider>
      <CookieConsent
        location="bottom"
        buttonText="Souhlasím"
        cookieName="CookiesBar"
        style={{
          background: '#ffffff',
          color: '#2c303b',
          zIndex: 1000,
          boxShadow: '1px 0px 30px 1px rgb(0 0 0 / 20%)',
        }}
        buttonStyle={{
          background: '#2e8855',
          color: '#ffffff',
          textTransform: 'uppercase',
          fontSize: '13px',
          borderRadius: '4px',
          padding: '10px',
        }}
      >
        {
          'Tyto webové stránky používají soubory cookie ke zlepšení uživatelského zážitku. Používáním našich webových stránek souhlasíte s ukládáním technických cookies.'
        }
      </CookieConsent>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
