import { z } from 'zod';

export const numberBetweenOrEmptyString = (startNum: number, endNum: number, errorMsg: string) =>
  z.union([
    z
      .string()
      .regex(/^[0-9]+$/, 'Pole musí být číslo, nebo zůstat prázdné')
      .refine((strNum) => Number(strNum) > startNum && Number(strNum) < endNum, errorMsg)
      .transform(Number),
    z.null(),
    z
      .string()
      .refine((strVal: string) => strVal === '' || strVal === null)
      .transform(() => undefined)
      .optional(),
  ]);
