import React from 'react';
import * as L from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet';

interface MarkerProps {
  coords: L.LatLngExpression;
  title: string;
  content?: string;
  handleClick?: (arg?: any) => void;
}

const MarkerWithPopup = (props: MarkerProps) => {
  const { coords, title, content, handleClick } = props;

  const markerIcon = L.icon({
    iconUrl: '/tree.png',
    iconSize: [40, 40],
    iconAnchor: [20,39],
  });

  return (
    <Marker position={coords} title={title} icon={markerIcon} onClick={handleClick}>
      <Tooltip permanent direction="bottom" offset={[0, 20]}>
        {content}
      </Tooltip>
    </Marker>
  );
};

export default MarkerWithPopup;
