export enum AdoptionStatus {
  RESERVED = 'RESERVED',
  ADOPTED = 'ADOPTED',
  EXPIRED = 'EXPIRED',
}

export interface AdoptionTableRecord {
  adoptionId: string;
  treeId: string;
  parentUserId: string;
  timestamp: string;

  status: AdoptionStatus;
  amount: number;
  parentName?: string;
  parentAddress?: string;
  parentTaxNumber?: string;
}

export type TreeAdoptionRecord = Pick<AdoptionTableRecord, 'parentUserId' | 'status'> & {
  parentNickname?: string;
};
