import styled from 'styled-components';
import { Color } from 'src/styles/colors';

interface ButtonProps {
  disabled?: boolean;
  isActive?: boolean;
  align?: 'start' | 'center';
}

export default styled.div<ButtonProps>`
  width: 100%;
  height: 64px;
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || 'initial'};

  background-color: ${(props) => (props.isActive ? '#2c303b' : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : props.disabled ? Color.GRAY_40 : '#2c303b')};
  transition: background-color 0.3s;
  will-change: background-color;

  border-radius: 0%;

  cursor: pointer;
  box-shadow: none;
  outline: none;
  border: none;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;

  ${(props) => props.disabled && 'cursor: default;'}

  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'initial' : props.isActive ? '#2c303b' : '#f2f2f2'};
    color: ${(props) => (props.disabled ? Color.GRAY_40 : props.isActive ? '#fff' : '#2c303b')};
  }
`;
