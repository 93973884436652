import { createTheme } from '@material-ui/core';
import { colors } from './colors';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.primary,
      contrastText: colors.tertiary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: { main: colors.error },
  },
  zIndex: {
    drawer: 1200,
  },

  overrides: {
    MuiDrawer: {
      paper: {
        width: '100%',
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      },
    },
    MuiAppBar: {
      root: {
        flexDirection: 'row',
        padding: '10px 0',
        alignItems: 'center',
      },
    },
  },
});
