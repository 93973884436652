import { FC, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { ROUTE } from 'src/router/routes';

interface BaseProps {
  title: string;
  message: string;
  open: boolean;
  onClose: (e: MouseEvent) => void;
  actionLabel: string;
  cancelLabel: string;
}

interface ActionClickProps extends BaseProps {
  onActionClick: () => void;
  actionUrl?: never;
}

interface ActionUrlProps extends BaseProps {
  onActionClick?: never;
  actionUrl?: string;
}

type ActionProps = ActionClickProps | ActionUrlProps;

export const NonAccessModal: FC<ActionProps> = ({
  title,
  message,
  open,
  onClose,
  onActionClick,
  actionUrl,
  actionLabel,
  cancelLabel,
}) => {
  const splitedByNewline = message.split('\n');

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as HTMLElement)) {
        onClose(e);
      }
    };

    open && document.addEventListener('click', handleClickOutside, true);
  }, [open, onClose]);

  return (
    <Dialog open={open}>
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          {splitedByNewline.map((paragraph) => (
            <DialogContentText>{paragraph}</DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          {onActionClick && (
            <Button variant="contained" color="secondary" onClick={onActionClick}>
              {actionLabel}
            </Button>
          )}

          {actionUrl && (
            <Button variant="contained" color="secondary">
              <Link to={ROUTE.PROFILE} style={{ color: '#ffffff', textDecoration: 'none' }}>
                {actionLabel}
              </Link>
            </Button>
          )}

          <Button variant="contained" onClick={(e) => onClose(e as any)}>
            {cancelLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
