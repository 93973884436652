import { PanelWrapper } from './SidebarAbout.styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import WebsiteIcon from '@material-ui/icons/Language';

import { SidebarTitle } from '../SidebarItems.styles';
import { CardDescription } from 'src/components/$common/Card/CardDescription';
import { CardDescriptionTitle } from 'src/components/$common/Card/CardDescriptionTitle';
import { CardSocial } from 'src/components/$common/Card/CardSocial';

const SidebarAbout = () => {
  const t = {
    main: {
      title: 'O projektu',
      description:
        'Stromy to v posledních letech nemají lehké a během sucha občas potřebují naši pomoc. Správci zeleně ve městech nemají šanci je všechny zalít, takže je to na nás. Pojďme šetřit vodou a zalévat stromy, ať se tu jim i nám žije lépe! Naše mapa stromů ukáže ty, které potřebují zalít. U zalitého stromu zadáš počet litrů a brzy uvidíš, jak jsi mu pomohl. Pokud se zaregistruješ a budeš chtít, dáme Ti časem vědět, až bude Tvůj strom zase potřebovat zalít.',
    },
    contribution: {
      title: 'Jak to celé začalo?',
      description:
        'Za velkého sucha v roce 2019 jsem každý den procházel parkem v Brně u Janáčkova divadla a sledoval, jak tam stromy trpí suchem. Většina měla suché větve a mnohdy se v koruně třepotalo pár posledních lístků. Byli mezi nimi jak mladé stromky, tak i století mohykáni. Kdyby jim tak někdo vodu donesl, když už jejich kořeny na žádnou nedosáhnou! Jednou mi konečně došlo, že vlastně trpí zbytečně. Město je přece plné lidí, kteří se s nimi jistě rádi o trochu vody podělí. Když ušetří pár litrů denně nebo se občas osprchují bez mýdla a zalijí zachycenou vodou některý strom, mohou jich spoustu zachránit. Jen je potřeba je inspirovat, vše vysvětlit a chytře koordinovat. Nejlépe pomocí aplikace, která by ukázala, který strom potřebuje zalít a zájemcům to časem i připomene. Odborníci z Veřejné zeleně byli nápadem nadšení, poskytli data stromů pro první verzi naší aplikace a hned jsme spustili testovací fázi. Během prvního léta pak lidé zalévali asi 500 stromů a v participativním rozpočtu projekt podpořilo téměř 1500 lidí. A každý z nich jistě dokáže nejméně jeden strom zachránit. Od té doby jsme získali podporu odborníků z praxe, vědců, měst, různých organizací i médií. V roce 2021 projekt dokonce získal "Ekologického Oskara" v soutěži E.ON Energy Globe.',
    },
    socials: [
      { website: 'https://zalejme.cz', icon: 'Website' },
      { website: 'https://www.facebook.com/zalejme.cz/', icon: 'Facebook' },
    ],
  };
  return (
    <>
      <SidebarTitle>O projektu Zalejme</SidebarTitle>
      <PanelWrapper>
        <CardDescriptionTitle>{t.main.title}</CardDescriptionTitle>
        <CardDescription>{t.main.description}</CardDescription>
        <CardDescriptionTitle>{t.contribution.title}</CardDescriptionTitle>
        <CardDescription>{t.contribution.description}</CardDescription>

        <CardDescriptionTitle>S kým spolupracujeme?</CardDescriptionTitle>
        <CardDescription>
          K výpočtu potřeby zalití nám pomáhají data projektu{' '}
          <a href="https://www.intersucho.cz/cz">Intersucho</a>
        </CardDescription>
        <CardDescription>
          Na vývoji aplikace se podílí softwarové studio{' '}
          <a href="https://applifting.io/">Applifting</a>
        </CardDescription>
      </PanelWrapper>

      <CardSocial>
        {t.socials.map((social) => (
          <a href={social.website} target="_blank" rel="noreferrer">
            {social.icon === 'Website' ? <WebsiteIcon /> : <FacebookIcon />}
          </a>
        ))}
      </CardSocial>
    </>
  );
};

export default SidebarAbout;
