import { device } from './../../styles/mediaQueries';
import styled from 'styled-components';
export const SIDEBAR_PANE_WIDTH = 460;
export const SIDEBAR_PANE_MARGIN = 16;
export const MOBILE_SIDEBAR_PANE_MARGIN = 10;

const hiddenOffset = SIDEBAR_PANE_WIDTH + 2 * SIDEBAR_PANE_MARGIN;
const shownOffset = hiddenOffset + SIDEBAR_PANE_MARGIN;

export interface StyledProps {
  showSidebar?: boolean | any;
  isTreeDetail?: boolean;
  position?: 'top' | 'bottom';
}

export const SidebarPane = styled.div<StyledProps>`
  position: absolute;
  left: -${hiddenOffset}px;
  top: 16px;
  height: calc(100% - 32px);
  width: ${SIDEBAR_PANE_WIDTH}px;
  box-sizing: border-box;

  background: white;
  z-index: 999;
  box-shadow: 0 2px 40px 0 rgba(30, 55, 145, 0.15);
  overflow: hidden scroll;

  transition: 500ms;
  transition-property: transform;
  transform: ${(props) => (props.showSidebar ? `translate3d(${shownOffset}px, 0, 0)` : '')};

  @media ${device.mobileMax} {
    top: 86px;
    left: initial;
    right: calc(100%);
    width: calc(100% - 10px - 10px);
    height: calc(100% - 64px - 10px - 10px - 30px);
    transform: ${(props) => (props.showSidebar ? 'translate3d(calc(100% + 10px), 0, 0)' : '')};
  }
`;

export const SidebarContent = styled.div`
  padding: 16px;
`;
