interface Props {
  condition: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement | null;
}

const ComponentWrapper: React.FC<Props> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children!) : children;

export default ComponentWrapper;
