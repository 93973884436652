const treeTaxons = [
  {
    id: '0676a783-b629-42d3-92b5-b01c067a9668',
    taxonLatin: 'Ailanthus Altissima',
    taxonCzech: 'Pajasan Žlaznatý',
  },
  {
    id: '62365dde-df44-4dbd-9eee-0efcf1b442da',
    taxonLatin: 'Alnus Glutinosa',
    taxonCzech: 'Olše Lepkavá',
  },
  {
    id: '2c3076f7-d1b0-457a-ba49-747ecb55835d',
    taxonLatin: 'Alnus Incana',
    taxonCzech: 'Olše Šedá',
  },
  {
    id: '0e963a03-f9a8-4363-927a-f9802eb28c5c',
    taxonLatin: 'Betula Pendula',
    taxonCzech: 'Bříza Bělokorá',
  },
  {
    id: 'ae2b9ec9-e2c4-483b-96ff-23edd3aab1b8',
    taxonLatin: 'Betula Pubescens',
    taxonCzech: 'Bříza Pýřitá',
  },
  {
    id: 'aaceb964-f6fc-4753-a1fa-a64288b5d6f0',
    taxonLatin: 'Negundo Aceroides (Acer Negundo)',
    taxonCzech: 'Javorovec Jasanolistý',
  },
  {
    id: '15caadb9-7f98-4ffd-922b-ab2c4107139d',
    taxonLatin: 'Populus × Berolinensis',
    taxonCzech: 'Topol Berlínský',
  },
  {
    id: '9feb7ff6-61c6-4456-bfe9-f9a320bbf305',
    taxonLatin: 'Populus × Canadensis',
    taxonCzech: 'Topol Kanadský',
  },
  {
    id: 'fe129623-fcb0-46fd-bd95-f005d87c1819',
    taxonLatin: 'Populus × Canescens',
    taxonCzech: 'Topol Šedavý',
  },
  {
    id: '09498e8e-1d99-4d13-acac-e1ace7138ddf',
    taxonLatin: 'Populus Alba',
    taxonCzech: 'Topol Bílý',
  },
  {
    id: '4a967e7e-876f-4502-a6ac-9e01b09b357f',
    taxonLatin: 'Populus Balsamifera',
    taxonCzech: 'Topol Balzámový',
  },
  {
    id: 'e6c061d9-40b9-4c9c-af3d-841761141b03',
    taxonLatin: 'Populus Nigra',
    taxonCzech: 'Topol Černý',
  },
  {
    id: '3a1babc0-14bc-499d-b0c2-40a69c35512c',
    taxonLatin: 'Populus Simonii',
    taxonCzech: 'Topol Simonův',
  },
  {
    id: '76aa9619-c5af-4db1-8006-b7f7d1d7310d',
    taxonLatin: 'Populus Tremula',
    taxonCzech: 'Topol Osika',
  },
  {
    id: 'ba46816c-73c9-45b5-96aa-fcbdda1f49d1',
    taxonLatin: 'Populus Trichocarpa',
    taxonCzech: 'Topol Chlupatoplodý',
  },
  {
    id: '8af76894-fca4-4e96-84c9-59c0e15bdde8',
    taxonLatin: 'Robinia Pseudoacacia',
    taxonCzech: 'Trnovník Akát',
  },
  {
    id: '8490bc62-88e9-4415-bcd5-d0a0fa278195',
    taxonLatin: 'Salix Alba',
    taxonCzech: 'Vrba Bílá',
  },
  {
    id: 'e1593b17-dac5-41c9-bf84-af3dcbce1544',
    taxonLatin: 'Salix Fragilis',
    taxonCzech: 'Vrba Křehká',
  },
  {
    id: 'fa87aa15-6e13-4c7b-8087-2469ad1da2e6',
    taxonLatin: 'Acer Platanoides',
    taxonCzech: 'Javor Mléč',
  },
  {
    id: '703d25ff-22e5-4796-86a9-8532a047dafe',
    taxonLatin: 'Acer Pseudoplatanus',
    taxonCzech: 'Javor Klen',
  },
  {
    id: '85cb0e71-629d-4d76-b62b-370f3ec67621',
    taxonLatin: 'Acer Saccharinum',
    taxonCzech: 'Javor Stříbrný',
  },
  {
    id: '52cf622b-d7aa-40f8-9a6c-8c8709837541',
    taxonLatin: 'Aesculus Hippocastanum',
    taxonCzech: 'Jírovec Maďal',
  },
  {
    id: '1e0cef42-75af-49d3-997b-117083a9ef29',
    taxonLatin: 'Betula Ermanii',
    taxonCzech: 'Bříza Ermanova',
  },
  {
    id: 'fc7b02d7-90dd-4f38-ad40-6ae930de540e',
    taxonLatin: 'Betula Jacquemontii',
    taxonCzech: 'Bříza Jacquemontova',
  },
  {
    id: '8add3087-3a90-44fe-93e6-69dd50aba277',
    taxonLatin: 'Betula Lenta',
    taxonCzech: 'Bříza Tuhá',
  },
  {
    id: 'f18ea736-f3cf-42a9-bd41-0ad6e66d7c9f',
    taxonLatin: 'Betula Papyrifera',
    taxonCzech: 'Bříza Papírovitá',
  },
  {
    id: 'deacf29e-7ca2-4b96-b4c5-6420bd40457c',
    taxonLatin: 'Castanea Sativa',
    taxonCzech: 'Kaštanovník Jedlý',
  },
  {
    id: '9e81857a-9956-4de6-a4fd-d61f1f926f54',
    taxonLatin: 'Catalpa Bignonioides',
    taxonCzech: 'Katalpa Trubačovitá',
  },
  {
    id: '328150cf-7ec8-4ea5-b84c-becb9a3eea46',
    taxonLatin: 'Celtis Occidentalis',
    taxonCzech: 'Břestovec Západní',
  },
  {
    id: '996ed69d-7abf-4e29-ac32-208c8c10e103',
    taxonLatin: 'Cerasus (Prunus) Avium',
    taxonCzech: 'Třešeň Ptačí',
  },
  {
    id: '1ee7686f-815d-40eb-8695-9658a593d3f9',
    taxonLatin: 'Cerasus (Prunus) Mahaleb',
    taxonCzech: 'Mahalebka Obecná',
  },
  {
    id: '0761f60e-5f0c-46b6-bba1-aea286f7375b',
    taxonLatin: 'Cerasus (Prunus) Sargentii',
    taxonCzech: 'Třešeň Sargentova',
  },
  {
    id: 'df3c724f-c8a4-433e-89aa-e33a63872121',
    taxonLatin: 'Cercidophyllum Japonicum',
    taxonCzech: 'Zmarličník Japonský',
  },
  {
    id: '7038bb18-7552-4c1a-bf17-9ab03329e1e4',
    taxonLatin: 'Crataegus Sp.',
    taxonCzech: 'Hloh – Všechny Druhy',
  },
  {
    id: '6d401773-9c50-4ea4-8277-6441f5c956c3',
    taxonLatin: 'Fraxinus Excelsior',
    taxonCzech: 'Jasan Ztepilý',
  },
  {
    id: '68b66799-805e-4676-9ecb-8cb8857f9cc9',
    taxonLatin: 'Fraxinus Ornus',
    taxonCzech: 'Jasan Zimnář',
  },
  {
    id: 'bb84f254-868f-4737-8c02-7eb29c883dee',
    taxonLatin: 'Gymnocladus Dioicus',
    taxonCzech: 'Nahovětvec Dvoudomý',
  },
  {
    id: '48e31257-9b61-450a-a769-4ea0f5f9a63b',
    taxonLatin: 'Juglans Cinerea',
    taxonCzech: 'Ořešák Popelavý',
  },
  {
    id: '77f7165a-1ac9-4085-9c3c-9ae6ed47dd0b',
    taxonLatin: 'Juglans Nigra',
    taxonCzech: 'Ořešák Černý',
  },
  {
    id: '33c52e22-2661-4fe1-bc4b-68aed0210698',
    taxonLatin: 'Juglans Regia',
    taxonCzech: 'Ořešák Královský',
  },
  {
    id: 'cae08e48-669d-434d-bf3a-012514b4bb3d',
    taxonLatin: 'Magnolia Tripetala',
    taxonCzech: 'Magnolie (Šácholán)',
  },
  {
    id: '64649fe2-2d42-488a-82b9-1a038ee63fda',
    taxonLatin: 'Magnolia Acuminata',
    taxonCzech: 'Trojplátečná Magnolie (Šácholán)',
  },
  {
    id: 'bb52434e-3092-4ade-9249-16086802fcc8',
    taxonLatin: 'Magnolia Denudata',
    taxonCzech: 'Přišpičatělá Magnolie (Šácholán)',
  },
  {
    id: '94f11896-70c3-496e-9d85-5dccf17d22ab',
    taxonLatin: 'Magnolia Kobus',
    taxonCzech: 'Obnažená Magnolie (Šácholán) Kobus',
  },
  {
    id: '857e6ac3-91c4-4474-941c-0221acae3b1b',
    taxonLatin: 'Malus Sp.',
    taxonCzech: 'Jabloň – Botanické A Okrasné Druhy',
  },
  {
    id: '55cb34c4-d9a6-4f3b-bca9-4579a65a219c',
    taxonLatin: 'Morus Alba',
    taxonCzech: 'Morušovník Bílý',
  },
  {
    id: 'af3c718a-d339-48c1-94c0-3f483c6ada89',
    taxonLatin: 'Morus Nigra',
    taxonCzech: 'Morušovník Černý',
  },
  {
    id: '84908ae8-a172-4ce5-8863-f7791353eeaf',
    taxonLatin: 'Morus Rubra',
    taxonCzech: 'Morušovník Červený',
  },
  {
    id: '1488bec1-046d-4dc5-89f1-c98c909289b8',
    taxonLatin: 'Padus Avium (Prunus Padus)',
    taxonCzech: 'Střemcha Obecná',
  },
  {
    id: '7d4f5c2c-25b9-4ac2-8daa-0a3c1606ff25',
    taxonLatin: 'Paulownia Tomentosa',
    taxonCzech: 'Paulovnie Plstnatá',
  },
  {
    id: '26d2a54e-3513-4e00-9299-057534e58d3f',
    taxonLatin: 'Prunus Cerasifera',
    taxonCzech: 'Slivoň Myrabolán',
  },
  {
    id: '347900ee-130f-4b49-8880-ac86a707f0b7',
    taxonLatin: 'Pterocarya Fraxinifolia',
    taxonCzech: 'Pterokarye Jasanolistá',
  },
  {
    id: '180398d8-4d2d-4979-ba84-b80cded14f7e',
    taxonLatin: 'Pyrus Sp.',
    taxonCzech: 'Hrušeň – Botanické A Okrasné Druhy',
  },
  {
    id: 'ab1cb4fa-c305-4b8f-bcf5-7855214c10b6',
    taxonLatin: 'Sorbus Aucuparia',
    taxonCzech: 'Jeřáb Ptačí',
  },
  {
    id: '63f5424a-8ca7-45d2-beea-ebf6e5293780',
    taxonLatin: 'Sorbus Intermedia',
    taxonCzech: 'Jeřáb Prostřední',
  },
  {
    id: 'd7e61391-6c5b-42af-9106-9aca24ee5abe',
    taxonLatin: 'Ulmus Glabra',
    taxonCzech: 'Jilm Horský',
  },
  {
    id: '21491f4f-aae4-40ee-86e1-c7029a0fe2c2',
    taxonLatin: 'Ulmus Laevis',
    taxonCzech: 'Jilm Vaz',
  },
  {
    id: 'bd55463f-fbf4-4b82-930a-436d445c7d5d',
    taxonLatin: 'Ulmus Minor',
    taxonCzech: 'Jilm Habrolistý',
  },
  {
    id: '384e24cf-ec53-4ac5-9104-f24e4bcf20e9',
    taxonLatin: 'Acer Campestre',
    taxonCzech: 'Javor Babyka',
  },
  {
    id: 'c0f40adc-9acc-4745-bf09-fb65291a83ea',
    taxonLatin: 'Acer Rubrum',
    taxonCzech: 'Javor Červený',
  },
  {
    id: '0da755fd-ad92-4805-b9fb-6e4a08e594bf',
    taxonLatin: 'Aesculus × Carnea',
    taxonCzech: 'Jírovec Pleťový',
  },
  {
    id: '52e42a9c-cbe5-43ce-8d2f-b18597735821',
    taxonLatin: 'Carpinus Betulus',
    taxonCzech: 'Habr Obecný',
  },
  {
    id: 'aca8960d-7c6c-42fc-9e13-45a935228d85',
    taxonLatin: 'Carya Ovata',
    taxonCzech: 'Ořechovec Vejčitý',
  },
  {
    id: '22400749-04ba-45d3-a4e6-3204aee00f33',
    taxonLatin: 'Cerasus (Prunus) Fruticosa',
    taxonCzech: 'Třešeň Křovitá',
  },
  {
    id: 'b8ee6c8e-77ef-4c70-ac98-dd91a9323f20',
    taxonLatin: 'Cerasus (Prunus) Serrulata',
    taxonCzech: 'Třešeň Sakura',
  },
  {
    id: '9203f861-23c9-4a64-8532-52de8bf93d5e',
    taxonLatin: 'Cerasus (Prunus) Subhirtella',
    taxonCzech: 'Třešeň (Višeň) Chloupkatá',
  },
  {
    id: '0a179f3b-4abb-4fa4-8ea2-4ad416b2ab12',
    taxonLatin: 'Corylus Colurna',
    taxonCzech: 'Líska Turecká',
  },
  {
    id: '0f082436-4309-4d72-aded-007f881f9f93',
    taxonLatin: 'Fagus Sylvatica',
    taxonCzech: 'Buk Lesní',
  },
  {
    id: 'dd23f30b-19e6-412b-8653-8d344df4d10e',
    taxonLatin: 'Gleditsia Triacanthos',
    taxonCzech: 'Dřezovec Trojtrnný',
  },
  {
    id: '7e30dd37-d98c-4550-963d-8803643c46de',
    taxonLatin: 'Koelreuteria Paniculata',
    taxonCzech: 'Svitel Latnatý',
  },
  {
    id: 'b64813a8-1eab-46c4-9116-469dda276e8f',
    taxonLatin: 'Liquidambar Styraciflua',
    taxonCzech: 'Ambroň Západní',
  },
  {
    id: 'a39107d4-dd2c-41e7-a432-8fbb97c48519',
    taxonLatin: 'Liriodendron Tulipifera',
    taxonCzech: 'Liliovník Tulipánokvětý',
  },
  {
    id: '80fa9044-20f6-4451-9261-6c1cc09a54fe',
    taxonLatin: 'Phellodendron Amurense',
    taxonCzech: 'Korkovník Amurský',
  },
  {
    id: '48432445-a611-4b34-8a3b-c4ab14c9e5bb',
    taxonLatin: 'Platanus × Acerifolia (Hispanica)',
    taxonCzech: 'Platan Javorolistý',
  },
  {
    id: 'a13b5a9f-991b-4bfb-969a-3b10499df599',
    taxonLatin: 'Quercus Cerris',
    taxonCzech: 'Dub Cer',
  },
  {
    id: 'e330e515-50a0-4736-92de-4f90033ad105',
    taxonLatin: 'Quercus Coccinea',
    taxonCzech: 'Dub Šarlatový',
  },
  {
    id: '3023949d-9659-4a1b-b9f3-938f8686adf0',
    taxonLatin: 'Quercus Frainetto',
    taxonCzech: 'Dub Uherský',
  },
  {
    id: '8ec900dc-a0fc-4205-8760-795f034a0672',
    taxonLatin: 'Quercus Macranthera',
    taxonCzech: 'Dub Velkokvětý',
  },
  {
    id: '3281fe01-22f2-48b9-97d2-1004d8efb848',
    taxonLatin: 'Quercus Palustris',
    taxonCzech: 'Dub Bahenní',
  },
  {
    id: 'ff7b41ed-7845-4274-9f06-f62e6af62b62',
    taxonLatin: 'Quercus Petraea',
    taxonCzech: 'Dub Zimní',
  },
  {
    id: '0e508d8e-af16-408e-a7b9-b2e0f8364c6d',
    taxonLatin: 'Quercus Pubescens',
    taxonCzech: 'Dub Pýřitý',
  },
  {
    id: '0ba616c7-ef4e-4463-be29-5ce4355664de',
    taxonLatin: 'Quercus Robur',
    taxonCzech: 'Dub Letní',
  },
  {
    id: 'b3712445-510f-4182-91fc-fed62a3a5aca',
    taxonLatin: 'Quercus Rubra',
    taxonCzech: 'Dub Červený',
  },
  {
    id: '5bcfdf2f-c9a2-43b9-94c7-007efe7d0d7b',
    taxonLatin: 'Sophora Japonica',
    taxonCzech: 'Jerlín Japonský',
  },
  {
    id: '7066ebf9-8f0e-44ed-8d63-91b29e6742fd',
    taxonLatin: 'Sorbus Aria',
    taxonCzech: 'Jeřáb Muk',
  },
  {
    id: '58db629b-2b11-464b-9d03-381dfe81c19b',
    taxonLatin: 'Sorbus Domestica',
    taxonCzech: 'Jeřáb Oskeruše',
  },
  {
    id: '64798021-7735-41e3-8c8b-714be0955dde',
    taxonLatin: 'Sorbus Torminalis',
    taxonCzech: 'Jeřáb Břek',
  },
  {
    id: '6ad5695c-71f5-4ff6-a342-36c7fcbe62f8',
    taxonLatin: 'Tilia Americana',
    taxonCzech: 'Lípa Americká',
  },
  {
    id: '06ec201f-ff52-4aa4-a585-a42b2469ed2b',
    taxonLatin: 'Tilia Cordata',
    taxonCzech: 'Lípa Srdčitá',
  },
  {
    id: '40850c58-2f9f-4a22-9cc7-2a30d15f6f0e',
    taxonLatin: 'Tilia Euchlora',
    taxonCzech: 'Lípa Krymská',
  },
  {
    id: 'a1cf8c34-e0bd-4c62-99a4-5d811c1dafb0',
    taxonLatin: 'Tilia Petiolaris',
    taxonCzech: 'Lípa Řapíkatá',
  },
  {
    id: 'a24f0fe4-ebfa-427e-ab56-ea6875266dc4',
    taxonLatin: 'Tilia Platyphyllos',
    taxonCzech: 'Lípa Velkolistá',
  },
  {
    id: 'c493c525-663c-41b9-942e-9161e55f1f70',
    taxonLatin: 'Tilia Tomentosa',
    taxonCzech: 'Lípa Plstnatá',
  },
  {
    id: '0565be90-a09f-4883-80a3-7503e6152157',
    taxonLatin: 'Tilia Vulgaris (× Europaea)',
    taxonCzech: 'Lípa Obecná',
  },
  {
    id: '05dec8e2-45d1-4d09-8ccc-7d35c420b828',
    taxonLatin: 'Ulmus × Hollandica',
    taxonCzech: 'Jilm Holandský',
  },
  {
    id: 'e849a58e-caff-41fa-a192-3c598dd8f4d0',
    taxonLatin: 'Zelkova Carpinifolia',
    taxonCzech: 'Zelkova Habrolistá',
  },
  {
    id: '2bab136b-765d-4fa8-9796-e49befb0973d',
    taxonLatin: 'Abies Alba',
    taxonCzech: 'Jedle Bělokorá',
  },
  {
    id: '4108ecc1-ab55-4074-bccd-4eb3f8f8cd81',
    taxonLatin: 'Abies Grandis',
    taxonCzech: 'Jedle Obrovská',
  },
  {
    id: '22c7fe58-9f0c-43a2-9f26-811062becc69',
    taxonLatin: 'Chamaecyparis Lawsoniana',
    taxonCzech: 'Cypříšek Lawsonův',
  },
  {
    id: '892a9ae3-0a03-4896-a601-bba661049878',
    taxonLatin: 'Chamaecyparis Nootkatensis',
    taxonCzech: 'Cypříšek Nutka',
  },
  {
    id: '31ed4c40-d4d9-4b63-8098-e9bd84ad8230',
    taxonLatin: 'Chamaecyparis Pisifera',
    taxonCzech: 'Cypříšek Hrachonosný',
  },
  {
    id: '461eb506-ca3a-4157-bdbb-3222f7f8812b',
    taxonLatin: 'Larix Decidua',
    taxonCzech: 'Modřín Opadavý',
  },
  {
    id: 'b3cfd232-8c81-4fac-ac3f-db81e7f87795',
    taxonLatin: 'Picea Abies',
    taxonCzech: 'Smrk Ztepilý',
  },
  {
    id: '8a9dc61b-2df3-4f75-8b9a-eb74f2d66e02',
    taxonLatin: 'Pinus Sylvestris',
    taxonCzech: 'Borovice Lesní',
  },
  {
    id: 'a9dd7e97-2cae-433e-bc9d-5ba181c4f4eb',
    taxonLatin: 'Pinus Uncinata',
    taxonCzech: 'Borovice Pyrenejská',
  },
  {
    id: 'e9578c3e-0bbe-429c-8a63-d604fbc9e2d8',
    taxonLatin: 'Platycladus (Thuja) Orientalis',
    taxonCzech: 'Zeravec Východní',
  },
  {
    id: '3cd6c773-1df7-4ac6-bf6d-cf861e7662c5',
    taxonLatin: 'Pseudotsuga Menziesii',
    taxonCzech: 'Douglaska Tisolistá',
  },
  {
    id: '9712f131-8ed4-444c-a31e-3d3ae09bcc14',
    taxonLatin: 'Thuja Occidentalis',
    taxonCzech: 'Zerav Západní',
  },
  {
    id: '12993725-d2bc-41e4-800e-e143106e1e97',
    taxonLatin: 'Thuja Plicata',
    taxonCzech: 'Zerav Obrovský',
  },
  {
    id: '331ee398-6e2a-4363-bb6a-b7b337608cd8',
    taxonLatin: 'Abies Balsamea',
    taxonCzech: 'Jedle Balzámová',
  },
  {
    id: '4bd1011b-6e24-4f52-b9fa-22381e21b51c',
    taxonLatin: 'Abies Cephalonica',
    taxonCzech: 'Jedle Řecká',
  },
  {
    id: 'b6a4bde8-4b0a-4e4f-acd7-1f3a68e4bc67',
    taxonLatin: 'Abies Concolor',
    taxonCzech: 'Jedle Ojíněná',
  },
  {
    id: 'bbf355f9-7c7b-4b67-bd30-1f65d823aec6',
    taxonLatin: 'Abies Homolepis',
    taxonCzech: 'Jedle Nikko',
  },
  {
    id: '3edcbb9a-8335-4ea4-adcf-6a77049dedec',
    taxonLatin: 'Abies Koreana',
    taxonCzech: 'Jedle Korejská',
  },
  {
    id: '5b5fabf8-618f-46bf-97f0-cad5faba6c69',
    taxonLatin: 'Abies Nordmanniana',
    taxonCzech: 'Jedle Kavkazská',
  },
  {
    id: 'df642227-6f53-4217-ab30-5ec3540b59f1',
    taxonLatin: 'Abies Numidica',
    taxonCzech: 'Jedle Numidská',
  },
  {
    id: '1f922038-25a3-421d-9eba-9c28ce5378f9',
    taxonLatin: 'Abies Sibirica',
    taxonCzech: 'Jedle Sibiřská',
  },
  {
    id: 'bee3862f-64ad-4105-8053-7a643e00afc6',
    taxonLatin: 'Abies Veitchii',
    taxonCzech: 'Jedle Veitchova',
  },
  {
    id: '5eaeeecf-5e90-4d2c-926a-af1308006e5d',
    taxonLatin: 'Calocedrus Decurens',
    taxonCzech: 'Pazerav Cedrový',
  },
  {
    id: 'bac042b2-ce11-4ad0-9bcd-5dbb44f45664',
    taxonLatin: 'Cryptomeria Japonica',
    taxonCzech: 'Kryptomerie Japonská',
  },
  {
    id: '24eef2ad-863b-4a2c-827d-06dcd641863f',
    taxonLatin: 'Ginkgo Biloba',
    taxonCzech: 'Jinan Dvojlaločný',
  },
  {
    id: '8fad1510-2dd6-4a9f-ac5b-110ba2fb4cec',
    taxonLatin: 'Chamaecyparis Obtusa',
    taxonCzech: 'Cypříšek Tupolistý',
  },
  {
    id: 'c507b6e2-9949-4032-8566-e4f5a37c0095',
    taxonLatin: 'Chamaecyparis Thyoides',
    taxonCzech: 'Cypříšek Zeravovitý',
  },
  {
    id: 'd849fcd8-6874-4fcf-8fde-972a718bde24',
    taxonLatin: 'Juniperus Chinensis',
    taxonCzech: 'Jalovec Čínský',
  },
  {
    id: '4dc22f6a-0670-4e5e-9a05-7b88a0c5c41c',
    taxonLatin: 'Juniperus Scopulorum',
    taxonCzech: 'Jalovec Skalní',
  },
  {
    id: 'aae890b0-00e6-49b5-b879-ebf9a9857db6',
    taxonLatin: 'Juniperus Virginiana',
    taxonCzech: 'Jalovec Viržinský',
  },
  {
    id: '4dfffca3-c0c1-47c4-9dd5-a9e7c2a26a49',
    taxonLatin: 'Larix Kaempferi',
    taxonCzech: 'Modřín Japonský',
  },
  {
    id: '17a598a3-1d24-403e-8a33-13128cde1b41',
    taxonLatin: 'Larix Laricina',
    taxonCzech: 'Modřín Americký',
  },
  {
    id: '5b42aee9-ecec-42b4-a733-5f9ba661f96a',
    taxonLatin: 'Metasequoia Glyptostroboides',
    taxonCzech: 'Metasekvoje Tisovcovitá',
  },
  {
    id: '8bf8cc8b-c260-46b6-8b7f-ffd79de7f442',
    taxonLatin: 'Picea Engelmannii',
    taxonCzech: 'Smrk Engelmannův',
  },
  {
    id: 'fc5ca663-6f66-4e03-8db9-c59955d95f6a',
    taxonLatin: 'Picea Glauca',
    taxonCzech: 'Smrk Sivý',
  },
  {
    id: '972031ac-9adc-42c9-9655-734dd016c8dc',
    taxonLatin: 'Picea Jezoensis',
    taxonCzech: 'Smrk Ajanský',
  },
  {
    id: '2a4d4f4e-c452-4676-ac66-e8ae54c224c0',
    taxonLatin: 'Picea Mariana',
    taxonCzech: 'Smrk Černý',
  },
  {
    id: 'd11f177a-a2bb-43b3-8b9d-ce7098994fad',
    taxonLatin: 'Picea Omorika',
    taxonCzech: 'Smrk Omorika',
  },
  {
    id: '783a1ad7-9374-4c64-b36a-7f36afa3633a',
    taxonLatin: 'Picea Orientalis',
    taxonCzech: 'Smrk Východní',
  },
  {
    id: '14411cd2-3ae6-49f3-bd62-0bf24e29f1fc',
    taxonLatin: 'Picea Pungens',
    taxonCzech: 'Smrk Pichlavý',
  },
  {
    id: 'b205e2a7-9fbd-4589-abc7-fe25963a8cd3',
    taxonLatin: 'Picea Sitchensis',
    taxonCzech: 'Smrk Sitka',
  },
  {
    id: '02ced125-00ab-4a5c-a412-f545043ab629',
    taxonLatin: 'Pinus Banksiana',
    taxonCzech: 'Borovice Banksova',
  },
  {
    id: '8c60dc7d-03c1-48b2-8573-975b250642cb',
    taxonLatin: 'Pinus Contorta',
    taxonCzech: 'Borovice Pokroucená',
  },
  {
    id: '3389d8ec-7616-4001-bd00-834f2d30c0b2',
    taxonLatin: 'Pinus Flexilis',
    taxonCzech: 'Borovice Ohebná',
  },
  {
    id: 'aaafb140-e796-4f16-a2c7-c578df46a854',
    taxonLatin: 'Pinus Heldreichii',
    taxonCzech: 'Borovice Heldreichova',
  },
  {
    id: 'b5731c0f-ceab-4d6a-9e07-42dabca3ca5f',
    taxonLatin: 'Pinus Jeffreyi',
    taxonCzech: 'Borovice Jeffreyova',
  },
  {
    id: 'f2431065-313a-492f-be52-fc588a8114f9',
    taxonLatin: 'Pinus Koraiensis',
    taxonCzech: 'Borovice Korejská',
  },
  {
    id: '1b750f7e-bca1-4613-ad1b-c84c06266a8a',
    taxonLatin: 'Pinus Nigra',
    taxonCzech: 'Borovice Černá',
  },
  {
    id: 'ae99b8d7-c5fa-41fd-bed8-48189d185066',
    taxonLatin: 'Pinus Parviflora',
    taxonCzech: 'Borovice Drobnokvětá',
  },
  {
    id: '53cb7089-4795-41e0-be0f-32f4cb720513',
    taxonLatin: 'Pinus Peuce',
    taxonCzech: 'Borovice Rumelská',
  },
  {
    id: 'e74a0926-d7a6-4a5a-a71c-dd4aaa139c2d',
    taxonLatin: 'Pinus Ponderosa',
    taxonCzech: 'Borovice Žlutá',
  },
  {
    id: '726456c4-f833-4b5d-aef1-cae57970a40a',
    taxonLatin: 'Pinus Pungens',
    taxonCzech: 'Borovice Pichlavá',
  },
  {
    id: '69467d55-cc5f-4f7c-a1ac-fe73b2275860',
    taxonLatin: 'Pinus Resinosa',
    taxonCzech: 'Borovice Smolná',
  },
  {
    id: '7c34983c-aebc-42c7-a607-f2b8dfefe5b8',
    taxonLatin: 'Pinus Rigida',
    taxonCzech: 'Borovice Tuhá',
  },
  {
    id: 'a45d4413-a50d-44a7-b749-538d1669edad',
    taxonLatin: 'Pinus Rotundata',
    taxonCzech: 'Borovice Blatka',
  },
  {
    id: '7d0a3557-9569-4a9c-8444-281047be81b5',
    taxonLatin: 'Pinus Strobus',
    taxonCzech: 'Borovice Vejmutovka',
  },
  {
    id: 'd68541c8-7de8-4114-9922-95645d8fa6ec',
    taxonLatin: 'Pinus Wallichiana',
    taxonCzech: 'Borovice Himalájská',
  },
  {
    id: '4412ac03-01a6-4d89-a3ff-234d345f02f3',
    taxonLatin: 'Sequoiadendron Giganteum',
    taxonCzech: 'Sekvojovec Obrovský',
  },
  {
    id: 'd93d63ff-6eb0-44be-80cd-272f0184cf6b',
    taxonLatin: 'Thuja Standishii',
    taxonCzech: 'Zerav Japonský',
  },
  {
    id: '19545836-4189-4745-a802-b1da16dac8a0',
    taxonLatin: 'Thujopsis Dolabrata',
    taxonCzech: 'Zeravinec Japonský',
  },
  {
    id: 'c9ff05d2-1936-4176-b9b9-7d0afc2deea4',
    taxonLatin: 'Tsuga Mertensiana',
    taxonCzech: 'Jedlovec Mertensův',
  },
  {
    id: '7161b1e8-62db-4429-98cc-5aed8436ff30',
    taxonLatin: 'Tsuga Canadensis',
    taxonCzech: 'Jedlovec Kanadský',
  },
  {
    id: '8c55fbed-f9df-4e0b-a03c-b6a71e48594c',
    taxonLatin: 'Tsuga Heterophylla',
    taxonCzech: 'Jedlovec Západoamerický',
  },
  {
    id: '44484bfd-e7a2-418c-8d72-5588243a97e9',
    taxonLatin: 'Abies Amabilis',
    taxonCzech: 'Jedle Nádherná',
  },
  {
    id: '30cec8c1-5899-42e9-a569-9cfe23f4ca14',
    taxonLatin: 'Abies Lasiocarpa',
    taxonCzech: 'Jedle Subalpinská',
  },
  {
    id: 'e31b62ef-bb13-421a-88e4-adf693eef06d',
    taxonLatin: 'Abies Magnifica',
    taxonCzech: 'Jedle Nádherná',
  },
  {
    id: 'a910284d-9a21-4a08-a7a1-fcccf77a8be6',
    taxonLatin: 'Abies Pinsapo',
    taxonCzech: 'Jedle Španělská',
  },
  {
    id: '171d9f42-179f-472e-89a7-19b1de44ef9c',
    taxonLatin: 'Abies Procera',
    taxonCzech: 'Jedle Vznešená',
  },
  {
    id: '29148d78-4b81-4bbf-acf8-2651a7b6f34e',
    taxonLatin: 'Cedrus Atlantica',
    taxonCzech: 'Cedr Atlantský',
  },
  {
    id: '807c6155-1981-423e-a73a-37aa39805460',
    taxonLatin: 'Cedrus Deodara',
    taxonCzech: 'Cedr Himalájský',
  },
  {
    id: '4917b7dd-18cf-4d0c-a9cc-8838fe048a70',
    taxonLatin: 'Cedrus Libani',
    taxonCzech: 'Cedr Libanonský',
  },
  {
    id: '72d7dc89-7dbe-4aba-8fa2-d3530dd12146',
    taxonLatin: 'Picea Breweriana',
    taxonCzech: 'Smrk Brewerův',
  },
  {
    id: 'bd5b65f6-6941-4e62-8cc2-157986ddd80a',
    taxonLatin: 'Pinus Aristata',
    taxonCzech: 'Borovice Osinatá',
  },
  {
    id: '35b01fe0-4770-4de9-a8f7-0b4b928c552b',
    taxonLatin: 'Pinus Armandii',
    taxonCzech: 'Borovice Armandova',
  },
  {
    id: '97e5a17f-db88-49ab-9398-254e7cbd4994',
    taxonLatin: 'Pinus Cembra',
    taxonCzech: 'Borovice Limba',
  },
  {
    id: 'a8a01dcb-a649-49e5-80c3-e53c346b75b5',
    taxonLatin: 'Pinus Densiflora',
    taxonCzech: 'Borovice Hustokvětá',
  },
  {
    id: '32902ee5-422c-4278-ad12-0e305af2f044',
    taxonLatin: 'Pinus Monticola',
    taxonCzech: 'Borovice Pohorská',
  },
  {
    id: 'ad1ad515-ff04-4d6f-bc75-93f71d7b9633',
    taxonLatin: 'Pseudolarix Amabilis',
    taxonCzech: 'Pamodřín Líbezný',
  },
  {
    id: '5dbc4424-90b1-4d8c-892c-762d38207287',
    taxonLatin: 'Taxodium Distichum',
    taxonCzech: 'Tisovec Dvouřadý',
  },
  {
    id: '2c387500-6bbe-4e75-8dc5-ade76ba00b8c',
    taxonLatin: 'Taxus Baccata',
    taxonCzech: 'Tis Červený',
  },
  {
    id: '0eb03337-ad97-4284-a6f2-fd04e02c2fc3',
    taxonLatin: 'Taxus Cuspidata',
    taxonCzech: 'Tis Japonský',
  },
  {
    id: 'b4c04161-db45-4837-aa52-2005f30fce1b',
    taxonLatin: 'Tsuga Caroliniana',
    taxonCzech: 'Jedlovec Karolínský',
  },
  {
    id: '8cb5d664-9f08-47f7-9b89-10992a7a9fe4',
    taxonLatin: 'Tsuga Diversifolia',
    taxonCzech: 'Jedlovec Různolist',
  },
];

export default treeTaxons;
