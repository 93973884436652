import { zodResolver } from '@hookform/resolvers/zod';
import {
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import ZalejmeTextField from 'src/components/$common/ZalejmeTextField/ZalejmeTextField';
import { FormArea } from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/EditModal/EditModal.styles';
import useApi from 'src/hooks/useApi';
import { API_ROUTES } from 'src/router/routes';
import { TreeAdoptionFormData, treeAdoptionSchema } from 'src/schema/adoption.schema';
import { theme } from 'src/styles/appTheme';
import { TreeDetail } from 'src/types/tree';

interface Props {
  treeDetail: TreeDetail;
  open: boolean;
  onClose: () => void;
  updateTreeData: () => void;
}

const MINIMAL_AMOUNT = 49

const AdoptionModal: React.FC<Props> = ({ open, onClose, treeDetail, updateTreeData }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const formMethods = useForm<TreeAdoptionFormData>({ resolver: zodResolver(treeAdoptionSchema) });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const onFormSubmit = async (data: TreeAdoptionFormData) => {
    setSubmitting(true);

    try {
      const [adoptionResponse] = await api.post(API_ROUTES.treeAdoption(treeDetail.id), data);

      if (!adoptionResponse) throw new Error('Během rezervace adopce stromu došlo k chybě');
      enqueueSnackbar(
        `Tvá adopce byla rezervována, následuj prosím pokyny v emailu k jejímu dokončení`,
        {
          variant: 'success',
        }
      );
      updateTreeData();
    } catch (error) {
      enqueueSnackbar(`Během rezervace adopce stromu došlo k chybě`, {
        variant: 'error',
      });
    }
    setSubmitting(false);
    onClose();
  };

  const introTextOwner = (
    <DialogContentText>
      Zde můžeš podpořit lepší péči pro mě a stromy v okolí. Uděláš tak radost i sobě nebo někomu
      blízkému. Stačí poslat částku nad {MINIMAL_AMOUNT} Kč na transparentní účet obce. Použij návod níže a vše
      proběhne automaticky. Co získáš?
      <ul>
        <li>Na rok se staneš mým adoptivním rodičem</li>
        <li>Můžeš přidat jméno, fotku a vzkaz nebo příběh stromu</li>
        <li>Získáš možnost úpravy priority zalévání</li>
        <li>Zvýrazníš strom na mapě a budeš-li chtít, zobrazíme u něj tvé jméno </li>
        {/* <li>Dárkový certifikát se jménem</li> */}
      </ul>
      <p>Jak na to?</p>
    </DialogContentText>
  );

  const introTextZalejme = (
    <DialogContentText>
      Tento správce zatím moji adopci nepodporuje. Můžeš ale podpořit naši iniciativu Zalejme a tím
      pomoct inspirovat ostatní k péči o stromy ve svém okolí. Stačí příspět částkou nad {MINIMAL_AMOUNT} Kč
      spolku ClimaCare z.s. který za iniciativou stojí. Podporou získáš stejné výhody jako běžnou
      adopcí stromu:
      <ul>
        <li>Na rok se staneš mým adoptivním rodičem</li>
        <li>Můžeš přidat jméno, fotku a vzkaz nebo příběh stromu</li>
        <li>Získáš možnost úpravy priority zalévání</li>
        <li>Zvýrazníš strom na mapě a budeš-li chtít, zobrazíme u něj tvé jméno </li>
        {/* <li>Dárkový certifikát se jménem</li> */}
      </ul>
      <p>Jak na to?</p>
    </DialogContentText>
  );

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>Adopce stromu</DialogTitle>
      <DialogContent>
        {treeDetail.adoptionType === 'owner' ? introTextOwner : introTextZalejme}

        <FormArea>
          <form id="adoption-form" onSubmit={handleSubmit(onFormSubmit)}>
            <InlineFormGroup>
              <DialogContentText>1. Zadej částku (minimálně {MINIMAL_AMOUNT} Kč):</DialogContentText>
              <ZalejmeTextField
                formMethods={formMethods}
                name="amount"
                label="Částka"
                variant="outlined"
                size="small"
                width="150px"
                inputProps={{
                  endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
                  inputMode: 'numeric',
                }}
              />
            </InlineFormGroup>
            <DialogContentText>
              2. Zadej email, kam Ti pošleme platební údaje a QR kód:
            </DialogContentText>
            <ZalejmeTextField
              formMethods={formMethods}
              name="email"
              label="Email"
              variant="outlined"
              size="small"
              width="100%"
            />

            <DialogContentText>
              3. Pokud chceš, zadej své jméno které zobrazíme v aplikaci v detailu stromu a na mapě
            </DialogContentText>
            <ZalejmeTextField
              formMethods={formMethods}
              name="parentNickname"
              label="Jméno / přezdívka"
              variant="outlined"
              size="small"
              width="100%"
            />

            <DialogContentText>
              4. Pokud chceš zaslat potvrzení o daru, zadej tyto údaje:
            </DialogContentText>

            <InlineFormGroup>
              <ZalejmeTextField
                formMethods={formMethods}
                name="parentName"
                label="Jméno a příjmení / Název firmy"
                variant="outlined"
                size="small"
                width="100%"
              />
              <ZalejmeTextField
                formMethods={formMethods}
                name="parentTaxNumber"
                label="IČO / DIČ firmy"
                variant="outlined"
                size="small"
                width="100%"
              />
            </InlineFormGroup>
            <ZalejmeTextField
              formMethods={formMethods}
              name="parentAddress"
              label="Adresa dárce / firmy"
              variant="outlined"
              size="small"
              width="100%"
            />

            <FormControl error={!!errors.consent} style={{ flexBasis: '100%' }}>
              <FormControlLabel
                style={{ alignItems: 'center' }}
                control={
                  <Controller
                    name="consent"
                    control={control}
                    render={({ field }) =>
                      <Checkbox
                        {...field}
                        value={field.value ?? false}
                        checked={field.value ?? false}
                      />
                    }
                  />
                }
                label={
                  <DialogContentText style={{ marginBottom: 0 }}>
                    Souhlasím se{' '}
                    <a href="https://zalejme.cz/static/pdf/zpracovani_udaju_adopce.pdf">
                      zpracováním osobních údajů
                    </a>{' '}
                    a <a href={treeDetail.adoptionConditionsUrl}>podmínkami adopce</a>
                  </DialogContentText>
                }
              />
              {!!errors.consent && <FormHelperText>{errors.consent.message}</FormHelperText>}
            </FormControl>

            <DialogContentText>
              5. Zkontroluj své údaje a potvrď svůj zájem o adopci. Na email ti pošleme pokyny k
              platbě a QR kód. Po uhrazení částky se staneš adoptivním rodičem stromu.
            </DialogContentText>
          </form>
        </FormArea>
      </DialogContent>

      <DialogActions>
        <Button form="adoption-form" type="submit" variant="contained" color="secondary">
          {isSubmitting && 'Odesílám...'}
          {!isSubmitting && 'Potvrdit'}
        </Button>
        <Button variant="contained" onClick={onClose}>
          Zrušit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InlineFormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  gap: 16px;
`;

export default AdoptionModal;
