import { Button } from '@material-ui/core';
import { device } from 'src/styles/mediaQueries';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  background: #f7fffa;
  color: #2c303b;
  border: 1px solid #2c303b;
  width: 250px;
  height: 50px;
  border-radius: 50px;
  box-shadow: none;
  &:hover {
    background: #2c303b;
    color: #f7fffa;
  }
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  font-size: 1rem;
  font-style: bold;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.mobileMax} {
    flex-direction: column;
  }
`;
