import { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AnimateHeight from 'react-animate-height';

import { SidebarText, SmallTitle } from 'src/components/Sidebar/SidebarItems/SidebarItems.styles';
import TreeInfoRow from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeInfoRow/TreeInfoRow';
import { TreeWateringRecord } from 'src/types/watering';

interface Props {
  lastWaterings: TreeWateringRecord[];
}

const renderWateringRecord = (watering: TreeWateringRecord, isLast?: boolean) => {
  const wateringDateString = new Date(watering.datetime).toLocaleString('cs-CZ', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const wateringString = `${isLast ? 'Poslední záznam: ' : ''} ${wateringDateString} (${
    watering.waterAmount
  }L)`;

  return <SidebarText key={watering.datetime}>{wateringString}</SidebarText>;
};

const TreeWateringRecords: React.FC<Props> = ({ lastWaterings }) => {
  const [expandWaterings, setExpandWaterings] = useState(false);

  const lastWateringsSorted =
    lastWaterings.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()) ||
    []; // TODO: remove after getting correct data

  return (
    <TreeInfoRow attribute="Záznamy zalití" icon={<AssignmentIcon />} oneRow={false}>
      <>
        {lastWaterings.length
          ? renderWateringRecord(lastWateringsSorted[0], true)
          : 'Tento strom nebyl zalit'}

        {lastWaterings.length > 1 && (
          <>
            <KeyboardArrowDownIcon
              onClick={() => setExpandWaterings(!expandWaterings)}
              className="absolute-svg"
            />
            <AnimateHeight duration={500} height={expandWaterings ? 'auto' : '0'}>
              <SmallTitle>Starší záznamy</SmallTitle>
              {lastWaterings
                .slice(1)
                .map((watering: TreeWateringRecord) => renderWateringRecord(watering))}
            </AnimateHeight>
          </>
        )}
      </>
    </TreeInfoRow>
  );
};

export default TreeWateringRecords;
