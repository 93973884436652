import * as geohash from 'ngeohash';
import L from 'leaflet';

import { CityData } from 'src/components/Map/Layers/CityLayer';
import { TreeProps } from 'src/types/tree';
import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';
import { NewCityData } from './../components/Map/Layers/CityLayer';

/**
 * http://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
 *
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
  var r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
};

// convert a number to a color using hsl
export const numberToColorHsl = (i: number): string => {
  // as the function expects a value between 0 and 1, and red = 0° and green = 120°
  // we convert the input to the appropriate hue value
  var hue = (i * 1.2) / 360;
  // we convert hsl to rgb (saturation 85%, lightness 45%)
  var rgb = hslToRgb(hue, 0.85, 0.45);
  // we format to css value and return
  return 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
};

export const getBoundsFromBoundingBox = (box: string[][]): L.LatLngBounds => {
  const floatBox = box.map(([lat, lon]) => [parseFloat(lat), parseFloat(lon)]);
  const upperLeftCorner = new L.LatLng(floatBox[0][0], floatBox[0][1]);
  const bottomRightCorner = new L.LatLng(floatBox[1][0], floatBox[1][1]);
  return new L.LatLngBounds(upperLeftCorner, bottomRightCorner);
};

export const detectCitiesInView = (
  cities: NewCityData[],
  viewport: L.LatLngBounds
): NewCityData[] => {
  const citiesInView = cities?.filter((city) => {
    const latLong = city.boundingBox.map((boundingBox) => {
      const geographicPoint = geohash.decode(boundingBox);
      return [geographicPoint.latitude.toString(), geographicPoint.longitude.toString()];
    });

    const cityBounds = getBoundsFromBoundingBox(latLong);

    return viewport.intersects(cityBounds);
  });

  return citiesInView;
};

export interface GeohashMap {
  [geohashSegment: string]: TreeProps[];
}

export interface GeohashStore {
  [cityName: string]: GeohashMap;
}

export const getGeohashesInBounds = (bounds: L.LatLngBounds) => {
  const { lat: x1, lng: y1 } = bounds.getSouthWest();
  const { lat: x2, lng: y2 } = bounds.getNorthEast();

  const geohashPrecision = parseInt(EnvHelpers.getVar(EnvVar.REACT_APP_GEOHASH_PRECISION));
  const viewportGeohashes = geohash.bboxes(x1, y1, x2, y2, geohashPrecision);

  return viewportGeohashes;
};

export const organizeTreesByCitiesAndHashes = (allData: CityData[]) => {
  const geohashStore: GeohashStore = {};
  const geohashPrecision = parseInt(EnvHelpers.getVar(EnvVar.REACT_APP_GEOHASH_PRECISION));

  for (const city of allData) {
    geohashStore[city.cityName] = {};

    for (const district of city.districts) {
      for (const tree of district.trees) {
        const geohashPrefix = tree.id.substring(0, geohashPrecision);
        const existingTreesInGeohash = geohashStore[city.cityName][geohashPrefix] || [];
        geohashStore[city.cityName][geohashPrefix] = existingTreesInGeohash.concat([tree]);
      }
    }
  }

  return geohashStore;
};

export enum City {
  CESKE_BUDEJOVICE = 'České Budějovice',
  BRNO = 'Brno',
  TISNOV = 'Tišnov',
  LITOMYSL = 'Litomyšl',
  VOHANCICE = 'Vohančice',
  NIHOV = 'Níhov',
  LUBNE = 'Lubné',
  ROJETIN = 'Rojetín',
  PREDKLASTERI = 'Předklášteří',
  BRANISKOV = 'Braníškov',
  BOROVNIK = 'Borovník',
  PRAHA = 'Praha',
}
