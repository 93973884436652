import { TreeDetail } from 'src/types/tree';
import { Color } from 'src/styles/colors';
import EditIcon from '@material-ui/icons/Edit';
import { StyledH3, StyledFlex } from 'src/styles/common.styles';
import {
  StyledHeader,
  StyledFields,
  StyledImageStory,
  StyledImageWrapper,
  StyledStory,
  StyledPrioritySection,
  StyledWateringPriorityValue,
} from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/SidebarAdoptionFields/SidebarAdoptionFields.styles';
import { useState } from 'react';
import { NonAccessModal } from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeWatering/NonAccessModal';
import AdoptionEditModal from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/AdoptionEditModal/AdoptionEditModal';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TreeWateringPriorityAdjustmentEnum,
  TreeFormAdoptionData,
} from 'src/schema/editTree.schema';
import { AdoptionStatus } from 'src/types/adoption.type';
import { ROUTE } from 'src/router/routes';

interface Props {
  treeDetail: TreeDetail;
  openAdoptionModal: () => void;
  isTreeAdopted: boolean;
  updateTreeData: () => void;
  isTreeReserved: boolean;
}

const SidebarAdoptionFields: React.FC<Props> = ({
  treeDetail,
  openAdoptionModal,
  isTreeAdopted,
  updateTreeData,
  isTreeReserved,
}) => {
  const [adoptionAuthWallOpen, setAdoptionAuthWallOpen] = useState(false);
  const [adoptionWallOpen, setAdoptionWallOpen] = useState(false);
  const [adoptionModalOpen, setAdoptionModalOpen] = useState(false);

  const { user, isAuthenticated } = useAuth0();

  const { treeStory, treeImageUrl, wateringPriorityAdjustment } = treeDetail;
  const canEditAdoptionInfo =
    isAuthenticated &&
    treeDetail.treeAdoption?.parentUserId === user?.sub &&
    treeDetail.treeAdoption?.status === AdoptionStatus.ADOPTED;

  const defaultAdoptedStory =
    'Už dlouhá léta v mé koruně zpívají ptáci a pod ní si hrají děti, setkávají se přátelé a schází zamilovaní. Dávám jim stín i pohodu a těším se na další příběhy, které tu budou prožívat.';
  const defaultPlaceholderStory =
    'Zatím nemám žádný příběh. Kdokoliv mě může adoptovat a napsat jej!';

  const displayedImageUrl = treeImageUrl || '/zalejme-placeholder.jpg';
  const displayedTreeStory = treeStory
    ? treeStory
    : isTreeAdopted
    ? defaultAdoptedStory
    : defaultPlaceholderStory;
  const displayedPriorityAdjustment =
    wateringPriorityAdjustment || TreeWateringPriorityAdjustmentEnum.Enum['střední'];

  const treeAdoptionData: TreeFormAdoptionData = {
    treeAdoption: {
      parentNickname: treeDetail.treeAdoption?.parentNickname!,
    },
    treeStory: treeDetail.treeStory,
    treeImageUrl: treeDetail.treeImageUrl,
    wateringPriorityAdjustment: treeDetail.wateringPriorityAdjustment,
  };

  const openEditAdoptionModal = () => {
    if (!isAuthenticated) return setAdoptionAuthWallOpen(true);
    if (!canEditAdoptionInfo) return setAdoptionWallOpen(true);
    return setAdoptionModalOpen(true);
  };

  return (
    <div>
      <StyledHeader direction="row" justify="space-between" align="center">
        <StyledH3>Něco o mně</StyledH3>
        <EditIcon onClick={!isTreeReserved ? openEditAdoptionModal : () => {}} />
      </StyledHeader>
      <StyledFields direction="column">
        <StyledImageStory gap={24}>
          <StyledImageWrapper>
            <img src={displayedImageUrl} alt="Obrázek stromu" />
          </StyledImageWrapper>
          <StyledStory>{displayedTreeStory}</StyledStory>
        </StyledImageStory>
        <StyledPrioritySection justify="space-between" align="center">
          <span>Priorita zalévání</span>
          <StyledFlex gap={8}>
            <StyledWateringPriorityValue
              active={
                displayedPriorityAdjustment === TreeWateringPriorityAdjustmentEnum.Enum['nízká']
              }
              color={Color.GREEN}
            >
              nízká
            </StyledWateringPriorityValue>
            <StyledWateringPriorityValue
              active={
                displayedPriorityAdjustment === TreeWateringPriorityAdjustmentEnum.Enum['střední']
              }
              color={Color.YELLOW}
            >
              střední
            </StyledWateringPriorityValue>
            <StyledWateringPriorityValue
              active={
                displayedPriorityAdjustment === TreeWateringPriorityAdjustmentEnum.Enum['vysoká']
              }
              color={Color.RED}
            >
              vysoká
            </StyledWateringPriorityValue>
          </StyledFlex>
        </StyledPrioritySection>
      </StyledFields>

      <NonAccessModal
        title={'Editace informací o stromu'}
        message={
          'Tuto sekci smí upravovat pouze adoptivní rodiče stromu. Pokud chceš podpořit strom a projekt Zalejme, můžeš strom adoptovat!'
        }
        open={adoptionWallOpen}
        onClose={() => setAdoptionWallOpen(false)}
        actionLabel="Adoptovat strom"
        cancelLabel="Zrušit"
        onActionClick={openAdoptionModal}
      />

      <NonAccessModal
        title={'Adopce stromu'}
        message={
          'Pro adopci stromu se musíš přihlásit. Pokud ještě nemáš účet, můžeš si jej zdarma vytvořit. Díky tomu získáš přístup také k dalším funkcím aplikace.'
        }
        open={adoptionAuthWallOpen}
        onClose={() => setAdoptionAuthWallOpen(false)}
        actionLabel="Přihlásit se / Zaregistrovat"
        cancelLabel="Zrušit"
        actionUrl={ROUTE.PROFILE}
      />

      <AdoptionEditModal
        open={adoptionModalOpen}
        onClose={() => setAdoptionModalOpen(false)}
        treeId={treeDetail.id}
        treeAdoptionData={treeAdoptionData}
        updateTreeData={updateTreeData}
      />
    </div>
  );
};

export default SidebarAdoptionFields;
