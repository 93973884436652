import { FC, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { UserContext } from 'src/context/UserContext';
import UserHelpers from 'src/helpers/user.helpers';

interface Props {
  treeId: string;
  isFavorite: boolean;
  showAddFavoriteDialog: () => void;
}

export const SidebarFavoriteTree: FC<Props> = ({ treeId, isFavorite, showAddFavoriteDialog }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const { refreshUserDetail } = useContext(UserContext);

  const removeMyTree = async () => {
    const token = await getAccessTokenSilently();

    try {
      await new UserHelpers().deleteFavoriteTree(treeId, token);
      refreshUserDetail();
      enqueueSnackbar('Strom byl odebrán z oblíbených stromů', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Nastal problém při odebírání stromu z oblíbených', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      {isFavorite ? (
        <Tooltip title="Odebrat z oblíbených" placement="bottom">
          <FavoriteIcon className="favorite" onClick={removeMyTree}></FavoriteIcon>
        </Tooltip>
      ) : (
        <Tooltip title="Přidat do oblíbených" placement="bottom">
          <FavoriteBorderIcon className="add-favorite" onClick={showAddFavoriteDialog} />
        </Tooltip>
      )}
    </>
  );
};
