import styled from 'styled-components';

export const FlexOuter = styled.div`
  display: flex;
  width: 50%;
`;

export const Flex = styled.div`
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(p) => p.theme.colorGreyLight};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
