import tinygradient from 'tinygradient';

export const appColors = {
  primary: '#424242',
  secondary: '#2e8855',
  secondaryDark: '#20613c',
  tertiary: '#cccccc',
  white: '#ffffff',
  error: '#c51515',
  blue: '#007eb1',
};

export const markerColors = {
  wateringNeedGray: '#878a88',
  wateringNeedRed: '#b53f3f',
  wateringNeedOrange: '#b17530',
  wateringNeedYellow: '#ada623',
  wateringNeedLightGreen: '#7ea933',
  wateringNeedDarkGreen: '#4cad45',
};

const gradient = tinygradient(['#b53f3f', '#b17530', '#ada623', '#7ea933', '#4cad45']);
export const fullGradient = gradient.rgb(127);

export const formColors = {
  inputBorder: '#c4c4c4',
};

export const colors = {
  ...appColors,
  ...markerColors,
  ...formColors,
};

export enum Color {
  GRAY_90 = '#181818',
  GRAY_80 = '#303030',
  GRAY_70 = '#484848',
  GRAY_60 = '#606060',
  GRAY_50 = '#787878',
  GRAY_40 = '#909090',
  GRAY_30 = '#A8A8A8',
  GRAY_20 = '#BEBEBE',
  GRAY_10 = '#D8D8D8',
  GRAY_5 = '#F0F0F0',

  YELLOW = '#faf884',
  GREEN = '#baed91',
  RED = '#fea3aa',
}
