import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { StyledInput, StyledSubmit, ErrorMessage } from './TreeWatering.styles';
import styled from 'styled-components';

interface ErrorProps {
  error: boolean;
  errorMessage?: string;
}

interface Props {
  submitHandler: (waterLevel: number) => void;
}

const WateringForm: Function = (props: Props) => {
  const { submitHandler } = props;
  const [value, setValue] = useState<number | string>('');
  const [error, setError] = useState<ErrorProps>({
    error: false,
    errorMessage: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const valueNumber = Number(value);

    event.preventDefault();
    if (isNaN(valueNumber)) {
      setError({
        error: true,
        errorMessage: 'Zadejte prosím číslo',
      });
    } else if (valueNumber > 50 || valueNumber <= 0) {
      setError({
        error: true,
        errorMessage: 'Zadejte prosím hodnotu od 1 do 50',
      });
    } else {
      setError({ error: false });
      submitHandler(valueNumber);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledInput
        value={value}
        variant="outlined"
        autoFocus
        onChange={handleChange}
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end">L</InputAdornment>,
          notched: false,
        }}
      />
      <StyledSubmit variant="contained" color="secondary" type="submit">
        Odeslat
      </StyledSubmit>
      {error.error && <ErrorMessage>{error.errorMessage}</ErrorMessage>}
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  padding-top: 10px;
`;

export default WateringForm;
