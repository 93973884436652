import React from 'react';
import { SidebarTitle } from '../SidebarItems.styles';
import Alert from '@material-ui/lab/Alert';
import { useAuth0 } from '@auth0/auth0-react';
import AuthSection from 'src/components/AuthSection/AuthSection';
import { SidebarMyTrees } from './SidebarMyTrees/SidebarMyTrees';
import SidebarUserStats from './SidebarUserStats/SidebarUserStats';

const SidebarProfile: React.FunctionComponent = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <SidebarTitle>Profil</SidebarTitle>
      <AuthSection />
      {isAuthenticated && (
        <>
          <h3>Moje statistiky</h3>
          <SidebarUserStats />

          <h3>Moje stromy</h3>
          <SidebarMyTrees />

          <h3>Moje organizace</h3>
          <Alert severity="info">
            Tato sekce bude v budoucnosti zobrazovat organizace, jejichž jste součástí
          </Alert>
        </>
      )}
    </>
  );
};
export default SidebarProfile;
