import { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import OpacityIcon from '@material-ui/icons/Opacity';
import { Alert } from '@material-ui/lab';

import { Container, ButtonsWrapper, StyledButton } from './TreeWatering.styles';
import Loader from 'src/components/Loader/Loader';
import { UserContext } from 'src/context/UserContext';
import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';
import WateringForm from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeWatering/WateringForm';

interface Props {
  treeId: string;
  isFavorite: boolean | undefined;
  showFavoriteTreeModal: () => void;
  showNonAccessModal: () => void;
}

const TreeWatering: React.FC<Props> = ({
  treeId,
  isFavorite,
  showFavoriteTreeModal,
  showNonAccessModal,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [hasWatered, setWatered] = useState(false);
  const [customValue, setCustomValue] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { refreshUserDetail } = useContext(UserContext);

  const wateringAmounts: number[] = [1, 2, 5, 10, 20];

  const submitWatering = async (waterLevel: number) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : showNonAccessModal();

    if (!token) {
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } as {
        [header: string]: string;
      },
      body: JSON.stringify({ waterAmount: waterLevel }),
    };
    if (token) {
      requestOptions.headers['Authorization'] = `Bearer ${token}`;
    }
    setWatered(false);
    setSubmitting(true);

    try {
      await fetch(
        `${EnvHelpers.getVar(EnvVar.REACT_APP_API_ENDPOINT)}/tree/${treeId}/water`,
        requestOptions
      );
      await new Promise((resolve) => setTimeout(resolve, 750));
      enqueueSnackbar('Vaše zalití bylo úspěšně zaznamenáno', { variant: 'success' });
      if (isAuthenticated) refreshUserDetail();
      setWatered(true);
    } catch (e) {
      enqueueSnackbar('Během záznamu zalití došlo k chybě', { variant: 'error' });
    }
    setSubmitting(false);
    setCustomValue(false);
  };

  return (
    <Container>
      {!isSubmitting && (
        <>
          <ButtonsWrapper>
            {wateringAmounts.map((level, i) => (
              <StyledButton
                startIcon={<OpacityIcon />}
                key={`water-level-${level}`}
                variant="outlined"
                onClick={() => submitWatering(level)}
              >
                {level}L
              </StyledButton>
            ))}
            <StyledButton
              startIcon={<OpacityIcon />}
              key={`water-level-custom`}
              variant="outlined"
              onClick={() => setCustomValue(true)}
            >
              ? L
            </StyledButton>
          </ButtonsWrapper>
          {customValue && <WateringForm submitHandler={submitWatering} />}
        </>
      )}
      {hasWatered && (
        <Alert>
          <p>Děkujeme za zalití!</p>
          {isAuthenticated && !isFavorite && (
            // TODO: Replace anchor with actual button
            <p>
              Pokud chcete, můžete si tento strom{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={showFavoriteTreeModal}>
                přidat mezi své oblíbené stromy
              </a>
              .
            </p>
          )}
        </Alert>
      )}
      {isSubmitting && <Loader message=" " />}
    </Container>
  );
};

export default TreeWatering;
