import { z } from 'zod';

const zString = z.string({ required_error: 'Zadej prosím hodnotu' });

export const treeAdoptionSchema = z
  .object({
    amount: zString
      .regex(/^[0-9]+$/, 'Hodnota musí být číslo')
      .refine(
        (strNum) => Number(strNum) <= 50000 && Number(strNum) >= 49,
        'Adopční čáska musí být v rozmezí 49 - 50000 Kč'
      )
      .transform(Number),
    email: zString.nonempty('Zadej prosím hodnotu').email('Zadaný email je neplatný'),
    parentNickname: zString.max(20).optional(),
    parentName: zString.optional(),
    parentAddress: zString.optional(),
    parentTaxNumber: zString.optional(),
    consent: z
      .boolean({ required_error: 'Pro pokračování je potřeba souhlasit s podmínkami' })
      .refine((boolVal) => boolVal === true, 'Pro pokračování je potřeba souhlasit s podmínkami'),
  })
  .refine(
    (data) => {
      if ((data.parentAddress || data.parentName) && !(data.parentAddress && data.parentName)) {
        return false;
      }
      return true;
    },
    {
      message:
        'Vyplň prosím všechny volitelné údaje pro vystavení potvrzení o daru, nebo nech pole prázdná',
      path: ['parentAddress'],
    }
  );

export type TreeAdoptionFormData = z.infer<typeof treeAdoptionSchema>;
