import styled from 'styled-components';

interface StyledFlexProps {
  direction?: 'row' | 'column';
  justify?: 'space-between';
  align?: 'center';
  gap?: number;
}

export const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  ${(props) => `flex-direction: ${props.direction}`};
  ${(props) => `justify-content: ${props.justify}`};
  ${(props) => `align-items: ${props.align}`};
  ${(props) => `gap: ${props.gap}px`};
`;

export const StyledH3 = styled.h3`
  margin: 0;
`;
