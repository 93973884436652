import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export const DeactivatedLink = styled.span`
  pointer-events: none;
`;
