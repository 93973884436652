import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  useMediaQuery,
  Tooltip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import AnimateHeight from 'react-animate-height';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HelpIcon from '@material-ui/icons/Help';
import { v4 as uuidv4 } from 'uuid';
import sortBy from 'lodash.sortby';

import { TreeDetail } from 'src/types/tree';
import { updateTreeSchema, TreeFormData } from 'src/schema/editTree.schema';
import {
  Expand,
  FormArea,
} from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/EditModal/EditModal.styles';
import { theme } from 'src/styles/appTheme';
import ZalejmeSelect from 'src/components/$common/ZalejmeSelect/ZalejmeSelect';

import treeNames from 'src/json/treeNames';
import FormInputsType from 'src/json/fields.json';
import { FormInputsProps } from 'src/json/FormInputs';
import useApi from 'src/hooks/useApi';
import { API_ROUTES } from 'src/router/routes';

interface Props {
  open: boolean;
  onClose: () => void;
  treeData: TreeDetail;
  updateTreeData: () => void;
}

let treeTaxons = treeNames;

export const EditModal: FC<Props> = ({ open, onClose, treeData, updateTreeData }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [expandInputs, setExpandInputs] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { put } = useApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: zodResolver(updateTreeSchema),
  });

  useEffect(() => {
    const maybeTaxon = treeTaxons.find(
      (tree) => tree.taxonCzech === treeData.taxonCzech && tree.taxonLatin === treeData.taxonLatin
    );

    let taxonId = uuidv4();

    if (!maybeTaxon) {
      if (treeData.taxonCzech || treeData.taxonLatin) {
        treeTaxons = [
          {
            id: taxonId,
            taxonCzech: treeData.taxonCzech ?? '',
            taxonLatin: treeData.taxonLatin ?? '',
          },
          ...treeTaxons,
        ];
      }
    } else {
      taxonId = maybeTaxon.id;
    }

    const safeTreeData = Object.entries(treeData).reduce(
      (newTreeData, [field, value]) =>
        !value ? { ...newTreeData, [field]: undefined } : { ...newTreeData, [field]: value },
      {}
    );

    reset({ ...safeTreeData, taxonId });

    return () => {
      treeTaxons = treeNames;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FormInputs = FormInputsType as FormInputsProps[];

  const numbers = [...new Array(100)].map((_value, index) => (index + 1).toString());
  const now = new Date().getFullYear();
  const years = [...new Array(1000)].map((_value, index) => (now - index).toString());
  const czechSortedTrees = sortBy(treeTaxons, ['taxonCzech']);
  const latinSortedTrees = sortBy(treeTaxons, ['taxonLatin']);

  const autocompleteToOptions = {
    numbers,
    years,
  };

  const onFormSubmit = async (data: TreeFormData) => {
    setSubmitting(true);
    const maybeTaxon = treeTaxons.find((taxon) => taxon.id === data.taxonId);

    const { taxonId, ...prunedData } = data;
    const dataWithTaxon: TreeFormData = maybeTaxon
      ? { ...prunedData, taxonCzech: maybeTaxon.taxonCzech, taxonLatin: maybeTaxon.taxonLatin }
      : { ...prunedData, taxonCzech: null, taxonLatin: null };

    const updatedDataFields = Object.keys(dataWithTaxon);
    const updatedDataDiff = updatedDataFields.reduce((diff, field) => {
      // TODO: Verify if these two are safe assertions
      const newFieldValue = dataWithTaxon[field as keyof TreeFormData];
      const oldFieldValue = treeData[field as keyof TreeDetail];

      return newFieldValue !== oldFieldValue ? { ...diff, [field]: newFieldValue || null } : diff;
    }, {});

    try {
      await put(API_ROUTES.tree(treeData.id), updatedDataDiff);

      onClose();
      enqueueSnackbar(`Informace o stromu byly upraveny`, {
        variant: 'success',
      });
      updateTreeData();
    } catch (error) {
      enqueueSnackbar(`Během úpravy stromu došlo k chybě`, {
        variant: 'error',
      });
    }
    setSubmitting(false);
  };

  const displayInput = (input: FormInputsProps) => {
    const inputName = input.inputName as keyof TreeFormData;
    const error = errors[inputName];
    if (input.type === 'textField' && input.autocomplete) {
      return (
        <Controller
          key={inputName}
          name={inputName}
          control={control}
          render={({ field }) => {
            return (
              <Autocomplete
                {...field}
                freeSolo
                options={autocompleteToOptions[input.autocomplete!]}
                onChange={(_, value) => field.onChange(value)}
                onInputChange={(_, value) => field.onChange(value)}
                value={field.value?.toString() || ''}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label={input.label}
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!error?.message}
                      helperText={error?.message}
                      // TODO: Owner can edit. It needs to be done.
                      disabled={!!input.editOnlyOwner}
                      value={field.value || ''}
                    />
                    {input.helper && (
                      <Tooltip title={input.helper!} placement="bottom">
                        <HelpIcon className="helper" />
                      </Tooltip>
                    )}
                  </>
                )}
              />
            );
          }}
        />
      );
    }
    if (input.type === 'textField' && !input.autocomplete) {
      return (
        <div className="MuiWithoutAutocoplete" key={input.inputName}>
          <TextField
            {...register(inputName)}
            label={input.label}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            error={!!error?.message}
            helperText={error?.message}
            // TODO: Owner can edit. It needs to be done.
            disabled={!!input.editOnlyOwner}
          />
          {input.helper && (
            <Tooltip title={input.helper!} placement="bottom">
              <HelpIcon className="helper" />
            </Tooltip>
          )}
        </div>
      );
    }
    if (input.type === 'select') {
      return (
        <div className="MuiWithoutAutocoplete" key={input.inputName}>
          <ZalejmeSelect
            label={input.label}
            name={input.inputName}
            items={input.items!}
            register={register}
            control={control}
            disabled={!!input.editOnlyOwner}
          />
          {input.helper && (
            <Tooltip title={input.helper!} placement="bottom">
              <HelpIcon className="helper" />
            </Tooltip>
          )}
        </div>
      );
    }
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>Upravit informace o stromu</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Prosím zadej moje parametry pro přesnější odhad potřeby zalití. Čím víc jich zadáš, tím
          lépe. Pokročilé hodnocení vychází z{' '}
          <a
            href="https://www.ochranaprirody.cz/res/archive/385/062300.pdf?seek=1520256699"
            target="_blank"
            rel="noreferrer"
          >
            metodiky AOPK
          </a>
          .
        </DialogContentText>
        <FormArea>
          <form id="edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            {/* TODO: Refactor ZalejmeSelect and use that for this field */}
            <FormControl variant="standard">
              <FormLabel
                component="label"
                className="MuiInputLabel-shrink MuiInputLabel-formControl"
              >
                Český název
              </FormLabel>
              <Controller
                control={control}
                name="taxonId"
                render={({ field }) => (
                  <Select {...field} displayEmpty>
                    <MenuItem value="neuvedeno">- Neuvedeno -</MenuItem>
                    {czechSortedTrees.map((tree) => (
                      <MenuItem key={tree.id} value={tree.id}>
                        {tree.taxonCzech}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {/* TODO: Refactor ZalejmeSelect and use that for this field */}
            <FormControl variant="standard">
              <FormLabel
                component="label"
                className="MuiInputLabel-shrink MuiInputLabel-formControl"
              >
                Latinský název
              </FormLabel>
              <Controller
                control={control}
                name="taxonId"
                render={({ field: { value, onChange } }) => (
                  <Select value={value} onChange={onChange}>
                    <MenuItem value="neuvedeno">- Neuvedeno -</MenuItem>
                    {latinSortedTrees.map((tree) => (
                      <MenuItem key={tree.id} value={tree.id}>
                        {tree.taxonLatin}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {FormInputs.filter((input) => input.defaultHidden === false).map((input) =>
              displayInput(input)
            )}
            <Expand>
              <h4>Další informace</h4>
              <KeyboardArrowDownIcon
                onClick={() => setExpandInputs(!expandInputs)}
                className="absolute-svg"
              />
            </Expand>
            <AnimateHeight duration={500} height={expandInputs ? 'auto' : 0} className="hide">
              {FormInputs.filter((input) => input.defaultHidden).map((input) =>
                displayInput(input)
              )}
            </AnimateHeight>
          </form>
        </FormArea>
      </DialogContent>

      <DialogActions>
        <Button form="edit-form" type="submit" variant="contained" color="secondary">
          {isSubmitting && 'Odesílám...'}
          {!isSubmitting && 'Uložit'}
        </Button>
        <Button variant="contained" onClick={onClose}>
          Zrušit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
