import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core';
import { Control, Controller, UseFormRegister } from 'react-hook-form';

interface Props {
  label: string;
  name: string;
  items: string[] | number[];
  register: UseFormRegister<any>;
  control: Control<any, any>;
  disabled: boolean;
  blankOption?: boolean;
}

const ZalejmeSelect: React.FC<Props> = ({
  label,
  name,
  items,
  register,
  control,
  disabled,
  blankOption = true,
}) => (
  <FormControl variant="standard" key={name}>
    <FormLabel component="label" className="MuiInputLabel-shrink MuiInputLabel-formControl">
      {label}
    </FormLabel>
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Select label={label} name={name} value={value} onChange={onChange} disabled={disabled}>
          {blankOption && <MenuItem value="neuvedeno">- Neuvedeno -</MenuItem>}
          {items.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  </FormControl>
);
export default ZalejmeSelect;
