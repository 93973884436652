import { darken } from '@material-ui/core';
import { appColors, Color } from 'src/styles/colors';
import { StyledFlex } from 'src/styles/common.styles';
import styled from 'styled-components';

export const StyledHeader = styled(StyledFlex)`
  margin-top: 16px;

  svg {
    cursor: pointer;
  }
`;

export const StyledFields = styled(StyledFlex)`
  margin-bottom: 16px;
`;

export const StyledImageStory = styled(StyledFlex)`
  margin: 16px 0;
`;

export const StyledImageWrapper = styled.div`
  max-width: 100px;
  max-height: 100px;

  img {
    width: 100%;
    height: 100%;
  }

  padding: 8px;

  border: 1px solid ${appColors.tertiary};
  border-radius: 4px;
`;

export const StyledStory = styled.div`
  width: 100%;
  height: 100px;

  overflow-y: scroll;
  font-size: 14px;
  padding: 8px;

  border: 1px solid ${appColors.tertiary};
  border-radius: 4px;
`;

export const StyledPrioritySection = styled(StyledFlex)`
  font-size: 16px;
  font-weight: bold;
`;

export const StyledWateringPriorityValue = styled.div<{ active?: boolean; color: string }>`
  width: 60px;
  text-align: center;
  font-weight: normal;
  user-select: none;
  border: ${({ active, color }) => `2px solid ${active ? darken(color, 0.1) : Color.GRAY_20}`};
  background-color: ${({ active, color }) => (active ? color : Color.GRAY_10)};
  border-radius: 4px;
  padding: 4px;
`;
