import { Color, colors } from 'src/styles/colors';
import styled from 'styled-components';

// inline styles required for tooltip component in recharts
export const tooltipStyles = {
  backgroundColor: 'transparent',
  border: `1px solid ${colors.blue}`,
  zIndex: 3,
};

export const wrapperStyles = {
  backgroundColor: Color.GRAY_5,
  border: 0,
};

export const DescriptionText = styled.p`
  color: ${Color.GRAY_80};
  font-size: 15px;
  margin-top: 0;
`;
