import { Color } from 'src/styles/colors';
import styled from 'styled-components';
import { SIDEBAR_PANE_WIDTH, SIDEBAR_PANE_MARGIN } from '../Sidebar.styles';
import { device } from 'src/styles/mediaQueries';

export const SWITCHER_MARGIN = 16;
export const MOBILE_SWITCHER_MARGIN = 10;

const shownOffset = SIDEBAR_PANE_WIDTH + SIDEBAR_PANE_MARGIN;

interface SwitcherProps {
  isMobile: boolean;

  active?: boolean;
  isNavOpen?: boolean;
}

export const SwitcherWrapper = styled.div<SwitcherProps>`
  position: absolute;
  left: 0px;
  margin: ${(props) => (props.isMobile ? `${MOBILE_SWITCHER_MARGIN}px` : `${SWITCHER_MARGIN}px`)};

  z-index: 999;
  background: white;
  box-shadow: 0 2px 40px 0 rgba(30, 55, 145, 0.15);
  border: 1px solid ${Color.GRAY_10};

  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};

  transition: transform 500ms;
  transform: ${(props) => (props.isNavOpen ? `translate3d(${shownOffset}px, 0, 0)` : '')};

  @media ${device.mobileMax} {
    transform: none;
    transition: none;
  }
`;

export const SectionTitle = styled.div`
  padding-left: 4px;
  font-size: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ButtonDivider = styled.div`
  width: 'auto';
  border: 1px solid whitesmoke;
`;
