import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ZalejmeSelect from 'src/components/$common/ZalejmeSelect/ZalejmeSelect';
import ZalejmeTextField from 'src/components/$common/ZalejmeTextField/ZalejmeTextField';
import { FormArea } from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/EditModal/EditModal.styles';
import useApi from 'src/hooks/useApi';
import { API_ROUTES } from 'src/router/routes';
import {
  TreeFormAdoptionData,
  TreeWateringPriorityAdjustmentEnum,
  updateAdoptedTreeSchema,
} from 'src/schema/editTree.schema';
import { theme } from 'src/styles/appTheme';

interface Props {
  open: boolean;
  onClose: () => void;
  treeId: string;
  treeAdoptionData: TreeFormAdoptionData;
  updateTreeData: () => void;
}

const AdoptionEditModal: React.FC<Props> = ({
  open,
  onClose,
  treeId,
  treeAdoptionData,
  updateTreeData,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { put } = useApi();

  const formMethods = useForm({
    resolver: zodResolver(updateAdoptedTreeSchema),
    defaultValues: treeAdoptionData,
  });
  const { register, handleSubmit, control } = formMethods;

  const onFormSubmit = async (data: TreeFormAdoptionData) => {
    setSubmitting(true);

    try {
      await put(API_ROUTES.tree(treeId), data);

      enqueueSnackbar(`Informace o stromu byly upraveny`, {
        variant: 'success',
      });
      onClose();
      updateTreeData();
    } catch (error) {
      enqueueSnackbar(`Během úpravy stromu došlo k chybě`, {
        variant: 'error',
      });
    }

    setSubmitting(false);
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>Úprava informací</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Zde můžeš napsat můj příběh, nebo mi něco vzkázat, upravit svoji přezdívku, kterou budeme
          u stromu ukazovat v mapě. Můžeš také nahrát fotku a upravit prioritu zalévání. Vyšší
          priorita znamená, že potřebuji více vody.
        </DialogContentText>
        <FormArea>
          <form id="edit-adoption-form" onSubmit={handleSubmit(onFormSubmit)}>
            <ZalejmeTextField
              formMethods={formMethods}
              name="treeAdoption.parentNickname"
              label="Jméno / přezdívka adoptivního rodiče"
              variant="outlined"
              width="100%"
              size="small"
            />

            <ZalejmeTextField
              formMethods={formMethods}
              name="treeStory"
              label="Příběh stromu"
              variant="outlined"
              width="100%"
              fieldProps={{
                multiline: true,
                minRows: 4,
              }}
            />

            <ZalejmeSelect
              register={register}
              control={control}
              items={TreeWateringPriorityAdjustmentEnum.options as unknown as string[]}
              name="wateringPriorityAdjustment"
              label="Priorita zalévání"
              disabled={false}
              blankOption={false}
            />
          </form>
        </FormArea>
      </DialogContent>

      <DialogActions>
        <Button form="edit-adoption-form" type="submit" variant="contained" color="secondary">
          {isSubmitting && 'Odesílám...'}
          {!isSubmitting && 'Uložit'}
        </Button>
        <Button variant="contained" onClick={onClose}>
          Zrušit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdoptionEditModal;
