import { TreeWateringRecord } from './watering';
import {
  TreeAgeEnum,
  TreeCrownShapeEnum,
  TreeGrowthConditionEnum,
  TreeHealthEnum,
  TreePerspectiveEnum,
  TreeStabilityEnum,
  TreeVitalityEnum,
  YesNoEnum,
  TreeWateringPriorityAdjustmentEnum,
} from '../schema/editTree.schema';
import { z } from 'zod';
import { TreeAdoptionRecord } from 'src/types/adoption.type';
import { TreeDataWithWatering } from 'src/components/Map/Layers/CityLayer';

export interface TreeProps {
  id: string;
  latitude: string;
  longitude: string;
  wateringNeed: number;
  hasWateringHole: boolean;
  parentNickname?: string;
}

export interface TreeDetail extends TreeDetailsOptional {
  id: string;
  cityName: string;
  districtName: string;
  dailyPrecipitationSums: number[];
  waterRequired: number;
  waterReceived: number;
  lastWaterings: TreeWateringRecord[];
  adoptionType: 'owner' | 'zalejme';
  adoptionConditionsUrl: string;
}

export interface TreeDetailsOptional {
  latitude: number;
  longitude: number;
  taxonLatin?: string;
  taxonCzech?: string;
  detailedLocation?: string;
  height?: number;
  hasWateringHole?: z.infer<typeof YesNoEnum>;
  managedBy?: string;
  diameterTrunk?: number;
  diameterCrown?: number;
  heightBelowCrown?: number;
  crownShape?: z.infer<typeof TreeCrownShapeEnum>;
  growthConditions?: z.infer<typeof TreeGrowthConditionEnum>;
  vitality?: z.infer<typeof TreeVitalityEnum>;
  physiologicalAge?: z.infer<typeof TreeAgeEnum>;
  plantingYear?: number;
  plantingSeedingYear?: number;
  health?: z.infer<typeof TreeHealthEnum>;
  perspektive?: z.infer<typeof TreePerspectiveEnum>;
  stability?: z.infer<typeof TreeStabilityEnum>;
  hydrogel?: z.infer<typeof YesNoEnum>;
  wateringPriority?: number;
  noteToOwner?: string;
  noteFromOwner?: string;
  treeAdoption?: TreeAdoptionRecord;

  // Fields editable only by tree adopter
  treeStory: string;
  treeImageUrl: string;
  wateringPriorityAdjustment: z.infer<typeof TreeWateringPriorityAdjustmentEnum>;
}

export enum UserTreeAssociation {
  WATERED = 'WATERED',
  FAVORITE = 'FAVORITE',
  MODIFIED = 'MODIFIED',
  RESERVED = 'RESERVED',
  ADOPTED = 'ADOPTED',
}

interface AssociatedTree {
  treeId: string;
  treeName?: string;
  associations: UserTreeAssociation[];
}

export interface AssociatedTreeDetail extends AssociatedTree {
  taxonCzech?: string;
  districtName?: string;
  cityName?: string;
}

export type TreeData = { [treeGeohash: string]: TreeDataWithWatering };
export type GeohashSegmentTreeMap = { [geohashSegmentHash: string]: TreeDataWithWatering[] };

// const getOptionalAtributesFromTreeDetails = (details: TreeDetails): TreeDetailsOptional => {
//   const {
//     id,
//     cityName,
//     districtName,
//     dailyPrecipitation,
//     ...optionalAttributes
//   } = treeDetails;

//   return optionalAttributes;
// }
