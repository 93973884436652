import styled from 'styled-components';
import { appColors, Color } from 'src/styles/colors';

export const StyledDetailLink = styled.a`
  color: #4cad45;
  text-transform: capitalize;

  a:visited {
    color: #4cad45;
  }
`;

export const SidebarAdoptionSection = styled.div`
  margin-right: auto;
`;

export const SidebarTreeName = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  h3 {
    margin: 0;
  }
`;

export const SidebarAdoptedTitle = styled.div`
  font-size: 14px;
  color: ${Color.GRAY_80};
`;

export const SidebarTreeNameSection = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  svg {
    color: ${Color.GRAY_50};
    cursor: pointer;
    margin-right: 5px;

    &.favorite {
      color: ${appColors.secondary};

      &:hover {
        color: ${Color.GRAY_50};
      }
    }

    &.add-favorite:hover {
      color: ${appColors.secondary};
    }
  }
`;

export const AdoptedTreeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & div {
    font-size: 16px;
    font-weigth: normal;
  }
`;

export const AdoptionImage = styled.img<{ canAdopt: boolean }>`
  width: 48px;
  height: 48px;
  cursor: ${(props) => (props.canAdopt ? 'pointer' : 'default')};
`;

export const SidebarHeader = styled.div`
  display: flex;
`;
