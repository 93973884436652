import {
  AttributeName,
  AttributeValue,
  CategoryWrapper,
  Wrapper,
} from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeInfoRow/TreeInfoRow.styles';

export interface Props {
  icon: JSX.Element;
  attribute: string;
  value?: string | number | JSX.Element;
  children?: JSX.Element | JSX.Element[] | string;
  oneRow: boolean;
}

export const TreeInfoRow = ({ icon, attribute, value, children, oneRow }: Props) => {
  return (
    <Wrapper oneRow={oneRow}>
      <CategoryWrapper>
        {icon}
        <AttributeName>{attribute}</AttributeName>
      </CategoryWrapper>
      <AttributeValue>{value}</AttributeValue>
      {children}
    </Wrapper>
  );
};

export default TreeInfoRow;
