import * as geohash from 'ngeohash';

import { TreeProps } from '../../../types/tree';
import MarkerWithPopup from '../Marker';
import { LayerData, NewCityData } from './CityLayer';

export interface DistrictData {
  districtName: string;
  latitude: string;
  longitude: string;
  treeCount: string;
  trees: TreeProps[];
}

export interface NewDistrictData {
  districtName: string;
  geohash: string;
  treeCount: number;
}

const DistrictLayer = ({ shownCities, zoomIntoView }: LayerData) => {
  const defaultZoomLevel = 19;
  const districtMarkers = shownCities.flatMap((city: NewCityData) =>
    city.districts.map((district: NewDistrictData, i: number) => {
      const districtHash = geohash.decode(district.geohash);
      const districtCoords = [districtHash.latitude, districtHash.longitude] as L.LatLngTuple;

      // If district = city, use different zoom level
      const finalZoom = city.districts.length === 1 ? 16 : defaultZoomLevel;
      // If city has configured custom zoom center, use them instead
      const finalZoomcoords: L.LatLngTuple = city.customZoomCenter ?? districtCoords;

      return (
        <MarkerWithPopup
          key={district.geohash}
          coords={districtCoords}
          title={`${district.districtName} - ${district.treeCount}`}
          //title={district.districtName}
          //content={`${district.districtName} - ${district.treeCount}`}
          handleClick={() => zoomIntoView(finalZoomcoords, finalZoom)}
        />
      );
    })
  );

  return <>{districtMarkers}</>;
};

export default DistrictLayer;
