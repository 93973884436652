export enum EnvVar {
  REACT_APP_API_ENDPOINT = 'REACT_APP_API_ENDPOINT',
  REACT_APP_AUTH0_CLIENT_ID = 'REACT_APP_AUTH0_CLIENT_ID',
  REACT_APP_AUTH0_DOMAIN = 'REACT_APP_AUTH0_DOMAIN',
  REACT_APP_AUTH0_AUDIENCE = 'REACT_APP_AUTH0_AUDIENCE',
  REACT_APP_TREE_DATA_URL = 'REACT_APP_TREE_DATA_URL',
  REACT_APP_GEOHASH_PRECISION = 'REACT_APP_GEOHASH_PRECISION',
  REACT_APP_TREE_SATELLITE_URL = 'REACT_APP_TREE_SATELLITE_URL',
  REACT_APP_TREES_BUNDLE_DATA_URL = 'REACT_APP_TREES_BUNDLE_DATA_URL',
  REACT_APP_WATERING_DATA_URL = 'REACT_APP_WATERING_DATA_URL',
  REACT_APP_CITIES_DATA_URL = 'REACT_APP_CITIES_DATA_URL',
}

const getVar = (varName: EnvVar) => {
  const envVar = process.env[varName];

  if (!envVar) {
    console.error(`The requested environment variable '${varName} is missing!'`);
    throw new Error();
  }
  return envVar;
};

const getVars = (...varNames: EnvVar[]) => varNames.map(getVar);

const EnvHelpers = {
  getVar,
  getVars,
};
export default EnvHelpers;
