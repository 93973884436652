import { IconButton } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import { Link } from 'react-router-dom';
import { Color } from 'src/styles/colors';
import styled from 'styled-components';

export const TreeDetailLink = styled(Link)`
  color: black;
  text-decoration: none;
  padding-left: 12px;
`;

export const Content = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.2em;
  padding-top: 0.2em;
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: ${Color.GRAY_5};
    transition: background-color 0.1s linear;
  }
`;

export const TreeInfo = styled.div<{ wateringColor: string }>`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  border-left: 4px solid ${(props) => props.wateringColor};
  &:hover {
    text-decoration: none;
  }
`;

export const DeleteButton = styled(IconButton)`
  margin-left: 15px;
`;

export const TreeName = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 0;
`;

export const TaxonName = styled.p`
  font-size: 0.8em;
  display: flex;
  margin: 0;
  align-items: center;
  margin-bottom: 5px;
  span {
    margin-left: 5px;
  }
`;

export const ReservedTree = styled.div`
  font-size: 0.8rem;
  color: #bd4d17;
`;

export const LocationIcon = styled(HomeIcon)`
  display: block;
  margin-right: 5px;
`;

export const Location = styled.span`
  font-size: 0.8em;
`;
