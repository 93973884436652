import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

interface Props {
  message?: string;
}

const Loader: React.FunctionComponent<Props> = (props) => {
  const { message } = props;

  return (
    <Wrapper>
      <h4>{message || 'loading...'} </h4>
      <CircularProgress color="secondary" />
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
