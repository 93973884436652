import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import React, { useRef } from 'react';

import TreeInfoRow from '../TreeInfoRow/TreeInfoRow';
import { ScaleWrapper, Scale, NeedArrow, ScaleLabels } from './WateringScale.styles';

const WATERING_NEED_LABELS = ['Nutno zalít', 'Krátkodobý dostatek', 'Nezalévat'];

interface WateringScaleProps {
  wateringNeed: number;
}

const WateringScale: React.FC<WateringScaleProps> = ({ wateringNeed }) => {
  const scaleRef = useRef<HTMLDivElement>(null);
  const arrowLeftPosPercent = (wateringNeed / 127) * 100;

  const ScaleComponent = (
    <ScaleWrapper>
      <Scale ref={scaleRef}>
        <NeedArrow pos={arrowLeftPosPercent} />
      </Scale>
      <ScaleLabels>
        {WATERING_NEED_LABELS.map((label) => (
          <div key={label}>{label}</div>
        ))}
      </ScaleLabels>
    </ScaleWrapper>
  );

  return (
    <TreeInfoRow
      attribute="Stav vody"
      value={ScaleComponent}
      icon={<LocalDrinkIcon />}
      oneRow={false}
    />
  );
};

export default WateringScale;
