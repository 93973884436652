import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useQuery from 'src/hooks/useQuery';
import Alert from '@material-ui/lab/Alert';
import { AuthError } from 'src/types/auth';
import { FlexCol, AuthWrapper, StyledButton } from './AuthSection.styles';
import { CardParagraph } from '../$common/Card/CardParagraph';

const AuthSection: React.FunctionComponent = () => {
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const query = useQuery();

  const notAuthenticatedWall = (
    <FlexCol>
      <CardParagraph>
        Pro zobrazení profilu a možnost zalévat stromy je nutné se nejdříve registrovat nebo
        přihlásit ke svému účtu.
      </CardParagraph>
    </FlexCol>
  );

  const notVerifiedWall = (
    <FlexCol>
      <CardParagraph>Pro využívání všech funkcí je nutné dokončit vaši registraci.</CardParagraph>

      <Alert severity="info">Ověřte prosím svůj účet kliknutím na odkaz ve vašem emailu.</Alert>
    </FlexCol>
  );

  useEffect(() => {
    if (
      query &&
      query.error === 'unauthorized' &&
      query.error_description === AuthError.EMAIL_NOT_VERIFIED
    )
      setShowVerificationMessage(true);
  }, [query]);

  return (
    <>
      {!isAuthenticated && showVerificationMessage && notVerifiedWall}
      {!isAuthenticated && !showVerificationMessage && notAuthenticatedWall}
      <AuthWrapper>
        {isAuthenticated && (
          <CardParagraph>
            Jste přihlášen jako <b>{user!.email}</b>
          </CardParagraph>
        )}
        <StyledButton
          variant="contained"
          size="small"
          onClick={() =>
            isAuthenticated
              ? logout({ returnTo: window.location.origin })
              : loginWithRedirect({ ui_locales: 'cs' })
          }
        >
          {isAuthenticated ? 'Odhlásit' : 'Přihlásit se / Zaregistrovat'}
        </StyledButton>
      </AuthWrapper>
    </>
  );
};

export default AuthSection;
