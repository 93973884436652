import styled from 'styled-components';

export const CardDescription = styled.div`
  opacity: 0.66;
  font-size: 0.8rem;
  opacity: 0.66;
  line-height: 150%;
  width: 100%;
  transition: all 0.125s ease-in-out;
  a {
    color: #2c303b;
    &:hover {
      opacity: 0.5;
      transition: all 0.125s ease-in-out;
    }
  }
`;
