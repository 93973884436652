import { CityData, TreeDataWithWatering } from 'src/components/Map/Layers/CityLayer';
import { TreeDetail, TreeProps } from 'src/types/tree';
import { LatLngTuple } from 'leaflet';
import { numberToColorHsl } from './map.helpers';
import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';
import { decode } from 'ngeohash';

class TreeHelpers {
  fetchTreeDetail = async (treeId: string): Promise<TreeDetail | null> => {
    const resourceUri = `${EnvHelpers.getVar(EnvVar.REACT_APP_API_ENDPOINT)}/tree/${treeId}`;
    try {
      const response = await fetch(resourceUri);
      if (response.status !== 200) throw new Error('Tree detail fetch request failed!');

      const detail = await response.json();
      return detail.data as TreeDetail;
    } catch (err) {
      console.error(`Failed to fetch details for tree '${treeId}'!`);
      console.error(err);
      return null;
    }
  };

  calculateTreeWateringNeed = (treeDetail: TreeDetail, allTreeData: CityData[]) => {
    if (!allTreeData || !treeDetail) return 0;
    const { cityName, districtName, id } = treeDetail;

    const treeCity = allTreeData.find((city) => city.cityName === cityName);
    if (!treeCity) return 0;

    const treeDistrict = treeCity.districts.find(
      (district) => district.districtName === districtName
    );
    if (!treeDistrict) return 0;

    const tree = treeDistrict.trees.find((tree) => tree.id === id);
    if (!tree) return 0;

    return tree.wateringNeed;
  };

  findTree = (cityData: CityData[], treeId: string): TreeProps | undefined => {
    for (const i in cityData) {
      const districts = cityData[i].districts;
      for (const y in districts) {
        for (const treeIndex in districts[y].trees) {
          const tree = districts[y].trees[treeIndex];
          if (tree.id === treeId) return tree;
        }
      }
    }
    return undefined;
  };

  calculateWateringNeedColor = (wateringNeed: number): string => {
    return numberToColorHsl((wateringNeed / 127) * 100 || 0);
  };

  getTreePosition = (tree: TreeDataWithWatering): LatLngTuple => {
    const geoPoint = decode(tree.geohash);
    return [geoPoint.latitude, geoPoint.longitude];
  };
}

export default TreeHelpers;
