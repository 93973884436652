import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import OpacityIcon from '@material-ui/icons/Opacity';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import TreeInfoRow from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeInfoRow/TreeInfoRow';
import PrecipitationGraph from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/PrecipitationGraph/PrecipitationGraph';

interface Props {
  dailyPrecipitationSums: number[];
}

const Precipitation: React.FC<Props> = ({ dailyPrecipitationSums }) => {
  const [expandPrecipitation, setExpandPrecipitation] = useState(false);

  return (
    <TreeInfoRow attribute="Srážky" icon={<OpacityIcon />} oneRow={false}>
      <>
        <KeyboardArrowDownIcon
          onClick={() => setExpandPrecipitation(!expandPrecipitation)}
          className="absolute-svg"
        />
        <AnimateHeight duration={500} height={expandPrecipitation ? 'auto' : 0}>
          <PrecipitationGraph precipitationSums={dailyPrecipitationSums} />
        </AnimateHeight>
      </>
    </TreeInfoRow>
  );
};
export default Precipitation;
