import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';
import { UserDetail } from '../types/user';

export default class UserHelpers {
  public static instance: UserHelpers = new UserHelpers();

  async fetchUserDetails(token: string): Promise<UserDetail> {
    const resourceUri = `${EnvHelpers.getVar(EnvVar.REACT_APP_API_ENDPOINT)}/user`;
    const querySettings = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(resourceUri, querySettings);
    if (response.status !== 200) throw new Error('User detail fetch request failed!');
    const detail = await response.json();
    return detail.data as UserDetail;
  }

  async setFavoriteTree(treeId: string, treeName: string, token: string): Promise<Response> {
    const querySettings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        treeName: treeName,
      }),
    };
    const queryPath = `${EnvHelpers.getVar(
      EnvVar.REACT_APP_API_ENDPOINT
    )}/user/tree/${treeId}/favorite`;
    return fetch(queryPath, querySettings);
  }

  async deleteFavoriteTree(treeId: string, token: string): Promise<Response> {
    const querySettings = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    const queryPath = `${EnvHelpers.getVar(
      EnvVar.REACT_APP_API_ENDPOINT
    )}/user/tree/${treeId}/favorite`;
    return fetch(queryPath, querySettings);
  }

  isTreeFavorite(treeId: string, userDetails: UserDetail): boolean {
    return !!userDetails.associatedTrees.find((associatedTree) => associatedTree.treeId === treeId);
  }
}
