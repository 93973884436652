import { useState, useRef, useEffect } from 'react';
import * as L from 'leaflet';
import * as geohash from 'ngeohash';

import 'leaflet/dist/leaflet.css';
import { Map, Rectangle, TileLayer, ZoomControl } from 'react-leaflet';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import Sidebar from 'src/components/Sidebar/Sidebar';
import { useMediaQuery } from '@material-ui/core';
import { device } from '../../styles/mediaQueries';
import CityLayer, { FetchedCities, FetchedTreeData, NewCityData } from './Layers/CityLayer';
import DistrictLayer from './Layers/DistrictLayer';
import TreeLayer from './Layers/TreeLayer';
import { detectCitiesInView, getGeohashesInBounds } from 'src/helpers/map.helpers';
import { MapContext } from '../../context/MapContext';
import EnvHelpers, { EnvVar } from 'src/helpers/env.helpers';
import { SatelliteRecord } from 'src/types/satellite.type';
import { NonAccessModal } from 'src/components/Sidebar/SidebarItems/SidebarTreeDetail/TreeWatering/NonAccessModal';
import { useAuth0 } from '@auth0/auth0-react';
import { GeohashSegmentTreeMap, TreeData } from 'src/types/tree';
import { useLocation } from 'react-router-dom';
import TreeHelpers from 'src/helpers/tree.helpers';

export enum ViewType {
  TREES = 'SINGLE TREE VIEW',
  DISTRICTS = 'DISTRICTS VIEW',
  CITIES = 'CITES VIEW',
}

const geohashPrecision = parseInt(EnvHelpers.getVar(EnvVar.REACT_APP_GEOHASH_PRECISION));

const fetchDataFromCacheOrUrl = async <T extends Record<string, any>>(
  url: string,
  body: 'json' | 'text' = 'json'
): Promise<T> => {
  const zalejmeCache = await caches.open('zalejme');
  const maybeCachedResponse = await zalejmeCache.match(url);

  if (maybeCachedResponse) {
    // TODO: Always true?
    const lastEtag = maybeCachedResponse.headers.get('ETag')!;
    const newResponse = await fetch(url, {
      headers: { 'If-None-Match': lastEtag },
    });

    // Remote data has not changed, return data from cached response
    if (newResponse.status === 304) return maybeCachedResponse[body]();

    // Remote data has changed, update cache and return new data
    await zalejmeCache.put(url, newResponse.clone());
    return newResponse[body]();
  }

  // No cached response, request a fresh one
  const newResponse = await fetch(url);
  await zalejmeCache.put(url, newResponse.clone());

  return newResponse[body]();
};

export default function MapPage() {
  const [treeBundleData, setTreeBundleData] = useState<TreeData>();
  const [cityData, setCityData] = useState<NewCityData[]>();
  const [satelliteRecords, setSatelliteRecords] = useState<SatelliteRecord[] | null>();
  const [showIndexLayer, setShowIndexLayer] = useState(false);
  const [geohashSegmentMap, setGeohashSegmentMap] = useState<GeohashSegmentTreeMap>();

  const [citiesInView, setCitiesInView] = useState<NewCityData[]>([]);
  const [geohashesInView, setGeohashesInView] = useState<string[]>([]);
  const [viewType, setViewType] = useState<ViewType>(ViewType.CITIES);
  const [activeTreeCoords, setActiveTreeCoords] = useState<L.LatLngTuple | null>(null);
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(true);

  const [lastMapPos, setLastMapPos] = useLocalStorage<L.LatLng | null>('lastMapPos', null);
  const [lastMapZoom, setLastMapZoom] = useLocalStorage<number | null>('lastMapZoom', null);
  const [firstVisit, setFirstVisit] = useLocalStorage<boolean | null>('firstVisit', null);

  const previousZoomLevel = useRef(0);
  const mapRef = useRef<Map | null>(null);
  const canvas = L.canvas();
  const isMobile = useMediaQuery(device.mobileMax);
  const url = useLocation();

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    async function fetchTrees() {
      const fetchPromises = await Promise.all([
        fetchDataFromCacheOrUrl<FetchedTreeData>(
          EnvHelpers.getVar(EnvVar.REACT_APP_TREES_BUNDLE_DATA_URL)
        ),
        fetchDataFromCacheOrUrl<FetchedCities>(EnvHelpers.getVar(EnvVar.REACT_APP_CITIES_DATA_URL)),
        fetchDataFromCacheOrUrl(EnvHelpers.getVar(EnvVar.REACT_APP_WATERING_DATA_URL), 'text'),
        fetchDataFromCacheOrUrl<SatelliteRecord[]>(
          EnvHelpers.getVar(EnvVar.REACT_APP_TREE_SATELLITE_URL)
        ),
      ]);
      const [fetchedTreeData, fetchedCityData, fetchedWateringData, fetchedSatelliteData] =
        fetchPromises;
      const { trees: fetchedTreeMap } = fetchedTreeData;

      const [, data] = fetchedWateringData.split('\n');
      const watering = data.slice(data.indexOf('[') + 1, data.indexOf(']'));
      const wateringValues = watering.split(',') as TreeData[];

      const fetchedTrees = Object.values(fetchedTreeMap);
      const treesWithWateringNeed = wateringValues.reduce<TreeData>((trees, wateringValue, i) => {
        const newValue = {
          [fetchedTrees[i].geohash]: { ...fetchedTrees[i], wateringNeed: wateringValue },
        };
        return Object.assign(trees, newValue);
      }, {} as TreeData);

      const geohashSegmentMap = Object.values(treesWithWateringNeed).reduce<GeohashSegmentTreeMap>(
        (map, tree) => {
          const treeHashSegment = tree.geohash.substring(0, geohashPrecision);
          const newSegmentTrees = map[treeHashSegment] ? map[treeHashSegment].concat(tree) : [tree];
          return Object.assign(map, { [treeHashSegment]: newSegmentTrees });
        },
        {}
      );

      setTreeBundleData(treesWithWateringNeed);
      setCityData(fetchedCityData.cities);
      setGeohashSegmentMap(geohashSegmentMap);
      setSatelliteRecords(fetchedSatelliteData);
    }
    fetchTrees();
  }, []);

  useEffect(() => {
    if (firstVisit === null) setFirstVisit(true);
  }, [firstVisit, setFirstVisit]);

  const setViewableSources = (viewBounds: L.LatLngBounds) => {
    const oldCitiesInView = citiesInView;
    const newCitiesInView = detectCitiesInView(cityData!, viewBounds);
    // if no sources are in view, set the previous source so that it is never empty
    const citiesToShow = newCitiesInView || oldCitiesInView;
    setCitiesInView(citiesToShow);

    if (viewType === ViewType.TREES) {
      const viewport = mapRef.current?.leafletElement.getBounds() as L.LatLngBounds;
      const geohashesInViewport = getGeohashesInBounds(viewport);
      setGeohashesInView(geohashesInViewport);
    }
  };

  const centerIntoView = (
    coords: L.LatLngTuple,
    zoomLevel?: number,
    duration: number = 2,
    offsetCorrection: boolean = false
  ) => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
      // When on mobile, we want to shift the map center upwards somewhat so the active tree
      // is not obstructed by sidebar
      const coordsPixelLoc = map.latLngToContainerPoint(coords);
      const finalPixelLoc =
        isMobile && offsetCorrection
          ? new L.Point(coordsPixelLoc.x, coordsPixelLoc.y)
          : coordsPixelLoc;
      const finalCoords = map.containerPointToLatLng(finalPixelLoc);

      map.flyTo(finalCoords, zoomLevel, {
        duration,
        easeLinearity: 1,
      });
    }
  };

  const onMapReady = () => {
    if (!mapRef.current) return;

    const map = mapRef.current.leafletElement;

    // If the URL contains a specific tree, prioritize centering it into view over
    // restoring last known location and zoom level
    if (treeBundleData && url.pathname.includes('tree')) {
      const urlSegments = url.pathname.split('/');
      const maybeTreeId = urlSegments[urlSegments.length - 1];
      const maybeTree = treeBundleData[maybeTreeId];

      if (maybeTree) {
        const treeCoords = new TreeHelpers().getTreePosition(maybeTree);
        selectTree(treeCoords);
        map.setView(treeCoords, 18);
        map.flyTo(treeCoords, 18);
      }
    } else {
      // Restore last stored map position and zoom
      if (lastMapPos && lastMapZoom) {
        map.setView(lastMapPos, lastMapZoom);
        // Fly to the same spot to trigger the onMoveEnd handler
        map.flyTo(lastMapPos, lastMapZoom);
      } else {
        setViewableSources(map.getBounds());
      }
    }
  };

  const onMapZoom = () => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
      updateViewType(map.getZoom());
    }
  };

  const onMoveEnd = (e: L.LeafletEvent) => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
      setViewableSources(map.getBounds());
      setLastMapPos(map.getCenter());
      setLastMapZoom(map.getZoom());
    }
  };

  const updateViewType = (currentZoomLevel: number) => {
    if (currentZoomLevel === previousZoomLevel.current) return;

    if (currentZoomLevel <= 19 && currentZoomLevel >= 16) setViewType(ViewType.TREES);
    if (currentZoomLevel >= 12 && currentZoomLevel <= 15) setViewType(ViewType.DISTRICTS);
    if (currentZoomLevel <= 12) {
      setViewType(ViewType.CITIES);
      setShowIndexLayer(false);
    }

    previousZoomLevel.current = currentZoomLevel;
  };

  const selectTree = (treePosition: L.LatLngTuple, zoom: boolean = false) => {
    setActiveTreeCoords(treePosition);
    if (zoom) centerIntoView(treePosition, 19, 1, true);
  };

  const clearSelectedTree = () => {
    setActiveTreeCoords(null);
  };

  const displayMarkers = () => {
    switch (viewType) {
      case ViewType.CITIES:
        return <CityLayer shownCities={citiesInView} zoomIntoView={centerIntoView} />;
      case ViewType.DISTRICTS:
        return <DistrictLayer shownCities={citiesInView} zoomIntoView={centerIntoView} />;
      case ViewType.TREES:
        return (
          <TreeLayer
            activeTreeCoords={activeTreeCoords}
            geohashesInView={geohashesInView}
            geohashSegmentMap={geohashSegmentMap!}
            selectTree={selectTree}
            treeBundleData={treeBundleData!}
          />
        );
    }
  };

  // const displayCityBounds = (data: CityData[]) => {
  //   const districtBoxes = data.map((city) => {
  //     const floatBounds = city.boundingBox.map(([lat, lon]) => [parseFloat(lat), parseFloat(lon)]);
  //     const corner1 = new L.LatLng(floatBounds[0][0], floatBounds[0][1]);
  //     const corner2 = new L.LatLng(floatBounds[1][0], floatBounds[1][1]);
  //     const bounds = new L.LatLngBounds(corner1, corner2);
  //     return <Rectangle bounds={bounds} color="#057d37" />;
  //   });
  //   return districtBoxes;
  // };

  const displayViewportGeohashes = () => {
    const geohashRectangles = geohashesInView.map((hash) => {
      const bbox = geohash.decode_bbox(hash);
      const [x1, y1, x2, y2] = bbox;

      console.log(
        hash,
        `POLYGON((${y1} ${x1}, ${y2} ${x1}, ${y2} ${x2}, ${y1} ${x2}, ${y1} ${x1}))`
      );

      return (
        <Rectangle
          key={hash}
          bounds={[
            [x1, y1],
            [x2, y2],
          ]}
          color="#057d37"
        />
      );
    });
    return geohashRectangles;
  };

  const handleWelcomeModalActionClick = () => {
    loginWithRedirect({ ui_locales: 'cs' });
    setFirstVisit(false);
  };

  const handleWelcomeModalClose = (e: MouseEvent) => {
    if ((e.target as HTMLElement).tagName === 'SPAN') {
      setWelcomeModalOpen(false);
      setFirstVisit(false);
    }
  };

  return treeBundleData ? (
    <MapContext.Provider
      value={{
        centerIntoView,
        selectTree,
        clearSelectedTree,
        treeData: treeBundleData,
        setShowIndexLayer,
        showIndexLayer,
        currentViewType: viewType,
      }}
    >
      <Sidebar
        allTreeData={treeBundleData}
        activeTreeCoords={activeTreeCoords}
        clearSelectedTree={clearSelectedTree}
      />

      {firstVisit && (
        <NonAccessModal
          title={'Aplikace Zalej mě! pro zalévání stromů'}
          message={
            'V naší mapě uvidíte, jestli mají stromy žízeň. Pokud jsou stromy červené, budou určitě vděčné za zalití. Když strom zalijete, klikněte na něj v mapě a zadejte počet litrů. Mapa to zohlední a Váš strom trochu zezelená.\nPokud jej budete zalévat pravidelně, můžete si ho přidat do oblíbených a sledovat stav zalití ve svém profilu.\nStrom můžete i adoptovat a podpořit tak buď péči o zeleň ve svém městě (pokud to umožňuje), nebo naši iniciativu. Vaší odměnou bude i možnost dát stromu jméno, příběh a ovlivnit prioritu zalévání.\nPro zpřesnění odhadu zalití nám pomůže, když u stromu zadáte jeho rozměry, stáří a druh.\nDoufáme, že zalévání Vašim stromům pomůže a sami si to i užijete.\nPro zalévání a úpravu stromů se prosím přihlaste.'
          }
          open={welcomeModalOpen}
          onClose={handleWelcomeModalClose}
          onActionClick={handleWelcomeModalActionClick}
          actionLabel="Přihlásit se / Zaregistrovat"
          cancelLabel="Pokračovat bez přihlášení"
        />
      )}
      <Map
        zoomControl={false}
        ref={mapRef}
        className="leaflet-container"
        center={[49.8175, 15.473]}
        zoom={8}
        whenReady={onMapReady}
        onzoomend={onMapZoom}
        onmoveend={onMoveEnd}
        // onclick={(e) => console.log(e.latlng, geohash.encode(e.latlng.lat, e.latlng.lng, 11))}
        renderer={canvas}
        preferCanvas
      >
        <ZoomControl position="topright" />
        <TileLayer
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
          url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          maxZoom={19}
          subdomains={'abcd'}
          ext="png"
        />

        {showIndexLayer &&
          satelliteRecords &&
          [ViewType.DISTRICTS, ViewType.TREES].includes(viewType) &&
          satelliteRecords.map(({ geohash: hash, observationImageId }) => {
            const [x1, y1, x2, y2] = geohash.decode_bbox(hash);
            const latLngBounds: L.LatLngBoundsExpression = [
              [x1, y1],
              [x2, y2],
            ];

            const fullTileUrl = `https://tiler.worldfromspace.cz/{z}/{x}/{y}.png?url=https%3A%2F%2Fapi-dynacrop.worldfromspace.cz%2Fapi%2Ffile%2Fcolor%2F${observationImageId}.tiff%3Fstyle%3DEVI%26min_value%3D0.15%26max_value%3D0.95%26num_values%3D8&nodata=0&resampling_method=nearest`;

            return (
              <TileLayer
                key={hash}
                url={fullTileUrl}
                maxZoom={19}
                ext="png"
                opacity={0.3}
                bounds={latLngBounds}
              />
            );
          })}

        {treeBundleData && citiesInView && geohashSegmentMap && displayMarkers()}
        {/* Remove false to enable displays of city bounds or geohashes */}
        {/* {false && treeBundleData && displayCityBounds(treeBundleData)} */}
        {false && geohashesInView && displayViewportGeohashes()}
      </Map>
    </MapContext.Provider>
  ) : null;
}
