import styled from 'styled-components';
import { appColors, Color } from 'src/styles/colors';

export const SidebarTitle = styled.h2`
  justify-content: center;
  align-items: center;
  color: ${Color.GRAY_90};
  display: flex;
  margin: 0 0 32px 0;

  svg {
    color: ${Color.GRAY_50};
    cursor: pointer;
    margin-right: 5px;

    &.favorite {
      color: ${appColors.secondary};

      &:hover {
        color: ${Color.GRAY_50};
      }
    }

    &.add-favorite:hover {
      color: ${appColors.secondary};
    }
  }

  span {
    padding-left: 7px;
    text-transform: capitalize;
  }
`;

export const SidebarSubtitle = styled.h3`
  margin: 20px 0 8px 0;
`;

export const SidebarDescription = styled.p`
  color: ${Color.GRAY_80};
  font-size: 15px;
  margin-top: -25px;
  text-transform: capitalize;
`;

export const SidebarText = styled.div`
  color: ${Color.GRAY_80};
  font-size: 15px;
`;

export const WateringTitle = styled.h4`
  font-size: 15px;
  margin-bottom: 10px;
`;

export const EditTitle = styled.div`
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top 0px;
  }
`;

export const SmallTitle = styled.h5`
  font-size: 15px;
  margin: 5px 0 0 0;
`;

export const SidebarHistory = styled.div`
  color: ${Color.GRAY_80};
  cursor: pointer;
  font-size: 15px;
  margin-top: 10px;
  text-align: right;
  text-decoration: underline;

  &:first-letter {
    text-transform: uppercase;
  }
`;
