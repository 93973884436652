import { FC, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import UserHelpers from 'src/helpers/user.helpers';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { FavoriteTreeFormData } from 'src/types/myTrees';
import { Link } from 'react-router-dom';
import { ROUTE } from 'src/router/routes';

interface Props {
  treeId: string;
  treeAdded: () => void;
  open: boolean;
  onClose: () => void;
}

export const FavoriteTreeModal: FC<Props> = (props) => {
  const { open, onClose, treeAdded, treeId } = props;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FavoriteTreeFormData>();

  const onFormSubmit = async (data: FavoriteTreeFormData) => {
    setLoading(true);

    const token = await getAccessTokenSilently();

    try {
      await new UserHelpers().setFavoriteTree(treeId, data.treeName, token);
      enqueueSnackbar(`Strom byl přidán mezi oblíbené stromy`, {
        variant: 'success',
      });
      treeAdded();
    } catch (err) {
      enqueueSnackbar(
        'Nastal problém při přidávání stromu do oblíbených, zkuste to prosím později',
        { variant: 'error' }
      );
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Přidat strom do oblíbených?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Oblíbené stromy se zobrazují ve <Link to={ROUTE.PROFILE}>vašem profilu</Link>, v sekci{' '}
          <b>Moje Stromy</b>. Tato sekce zobrazuje současný stav stromů a poskytuje k nim snadný
          přístup.
        </DialogContentText>

        <DialogContentText>
          Pokud chcete tento strom přidat, přiřaďte mu prosím libovolný název:
        </DialogContentText>

        <form id="favorite-form" onSubmit={handleSubmit(onFormSubmit)}>
          {/* TODO: Check the given tree name is unique for user trees */}
          {/* TODO: Limit name length according to BE schema */}
          <TextField
            {...register('treeName', { required: 'Zadejte prosím název stromu' })}
            label="Název stromu"
            placeholder="např. Můj strom přes ulici"
            variant="standard"
            autoFocus
            disabled={loading}
            error={!!errors.treeName}
            helperText={errors.treeName?.message}
            InputLabelProps={{ shrink: true }}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          form="favorite-form"
          type="submit"
          variant="contained"
          color="secondary"
          disabled={loading}
        >
          {!loading && <span>Přidat strom</span>}
          {loading && <CircularProgress size={30} />}
        </Button>
        <Button variant="contained" onClick={onClose}>
          Zrušit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
