export const size = {
  mobileMin: '480px',
  mobileMax: '767px',
  tabletMin: '768px',
  tabletMax: '992px',
  laptopMin: '1024px',
  laptopMax: '1279px',
  desktopSmin: '1280px',
  desktopSmax: '1439px',
  desktopMmin: '1440px',
  desktopLMin: '1920px',
};

export const device = {
  mobileMin: `(min-width: ${size.mobileMin})`,
  mobileMax: `(max-width: ${size.mobileMax})`,
  tabletMin: `(min-width: ${size.tabletMin})`,
  tabletMax: `(max-width: ${size.tabletMax})`,
  laptopMin: `(min-width: ${size.laptopMin})`,
  laptopMax: `(max-width: ${size.laptopMax})`,
  desktopSmin: `(min-width: ${size.desktopSmin})`,
  desktopSmax: `(max-width: ${size.desktopSmax})`,
  desktopMmin: `(min-width: ${size.desktopMmin})`,
  desktopLmin: `(min-width: ${size.desktopLMin})`,
};
