import React, { Fragment } from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AccountCircle from '@material-ui/icons/AccountCircleOutlined';
import LayersIcon from '@material-ui/icons/Layers';

import { SwitcherWrapper, ButtonDivider, SectionTitle } from './SidebarSwitcher.styles';
import EdgeButton from 'src/components/EdgeButton/EdgeButton';

import ZalejmeLink from 'src/components/$common/ZalejmeLink/ZalejmeLink';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { device } from '../../../styles/mediaQueries';
import { MapContext } from 'src/context/MapContext';
import { useContext } from 'react';
import { ViewType } from 'src/components/Map/Map';
import ComponentWrapper from 'src/components/$common/ComponentWrapper';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  isSidebarShown: boolean;
  setSidebarShown: (isShown: boolean) => void;
  clearSelectedTree: () => void;
}

interface NavItem {
  path?: string;
  onClick?: () => void;
  isActive?: boolean;
  title: string;
  icon: React.ReactNode;
  mobileIcon: React.ReactNode;
  disabled: boolean;
  disabledTooltip?: string;
}

const SidebarSwitcher: React.FC<Props> = ({
  isSidebarShown,
  setSidebarShown,
  clearSelectedTree,
}) => {
  const { setShowIndexLayer, showIndexLayer, currentViewType } = useContext(MapContext);
  const { isAuthenticated } = useAuth0();

  const navItems: NavItem[] = [
    {
      path: '/about',
      title: 'Info',
      icon: <InfoIcon />,
      mobileIcon: <InfoIcon fontSize="large" />,
      disabled: false,
    },
    {
      path: '/profile',
      title: isAuthenticated ? 'Profil' : 'Přihlásit se',
      icon: <AccountCircle />,
      mobileIcon: <AccountCircle fontSize="large" />,
      disabled: false,
    },
    {
      onClick: () => {
        setShowIndexLayer(!showIndexLayer);
      },
      isActive: showIndexLayer,
      title: 'Sat. data',
      icon: <LayersIcon />,
      mobileIcon: <LayersIcon fontSize="large" />,
      disabled: currentViewType === ViewType.CITIES,
      disabledTooltip:
        'Satelitní vrsta je dostupná pouze na úrovni čtvrtí a blíže a pouze v lokalitách Brno-střed a Brno-Řečkovice',
    },
    // { path: '/stats', title: 'Statistika', icon: <TimelapseRoundedIcon />, notImplementedYet: true },
    // { path: '/addTree', title: 'Přidat strom', icon: <NaturePeopleIcon />, notImplementedYet: true }
  ];

  const { pathname } = window.location;
  const isMobile = useMediaQuery(device.mobileMax);

  const renderNavButton = (item: NavItem) => (
    <ComponentWrapper
      condition={!!item.disabledTooltip}
      wrapper={(children) => <Tooltip title="teesssst">{children}</Tooltip>}
    >
      <ComponentWrapper
        condition={!!item.path}
        wrapper={(children) => (
          <ZalejmeLink destination={item.path!} disabled={item.disabled}>
            {children}
          </ZalejmeLink>
        )}
      >
        <EdgeButton
          title={item.disabledTooltip}
          aria-label={item.title}
          isActive={item.isActive || pathname === item.path}
          disabled={item.disabled}
          align={isMobile ? 'center' : 'start'}
          style={{ width: isMobile ? '64px' : '128px' }}
          onClick={() => {
            if (item.disabled) return;
            clearSelectedTree();
            if (item.onClick) item.onClick();
          }}
        >
          {isMobile ? item.mobileIcon : item.icon}
          <SectionTitle>{item.title}</SectionTitle>
        </EdgeButton>
      </ComponentWrapper>
    </ComponentWrapper>
  );

  return (
    <SwitcherWrapper isNavOpen={isSidebarShown} isMobile={isMobile}>
      {navItems.map((item, i) => (
        <Fragment key={`${item.path}-${i}`}>
          {renderNavButton(item)}
          {i !== navItems.length - 1 && <ButtonDivider />}
        </Fragment>
      ))}
    </SwitcherWrapper>
  );
};

export default SidebarSwitcher;
