import { InputProps, TextField } from '@material-ui/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

interface Props {
  formMethods: UseFormReturn<any>;
  name: string;
  variant?: 'filled' | 'outlined';
  label?: string;
  shrink?: boolean;
  size?: 'medium' | 'small';
  width?: string;
  inputProps?: InputProps;
  fieldProps?: Object;
}

const ZalejmeTextField: React.FC<Props> = ({
  formMethods,
  name,
  variant = 'filled',
  label,
  shrink = true,
  size = 'medium',
  width,
  inputProps,
  fieldProps,
}) => {
  const {
    control,
    formState: { errors },
  } = formMethods;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledTextfield
          {...fieldProps}
          {...field}
          label={label}
          variant={variant}
          InputLabelProps={{ shrink }}
          InputProps={inputProps}
          size={size}
          width={width}
          error={!!errors[name]}
        />
      )}
    />
  );
};

const StyledTextfield = styled(TextField)<{ width?: string }>`
  && {
    flex-basis: ${(props) => props.width};
  }
`;

export default ZalejmeTextField;
