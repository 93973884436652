import styled from 'styled-components';
import { Color } from 'src/styles/colors';
interface WrapperProps {
  readonly oneRow: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  color: ${Color.GRAY_80} & a {
    color: violet;
  }
  display: flex;
  flex-direction: ${({ oneRow }) => (oneRow ? 'row' : 'column')};
  justify-content: ${({ oneRow }) => (oneRow ? 'space-between' : 'center')};
  padding: 7px 0;
  position: relative;

  .absolute-svg {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 7px;
  }
`;

export const AttributeName = styled.h4`
  font-size: 15px;
  margin: 0;
  padding-right: 40px;
`;

export const AttributeValue = styled.div`
  color: ${Color.GRAY_80};
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  white-space: nowrap;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const CategoryWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-right: 40px;
  flex-shrink: 0;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }
`;
