import styled from 'styled-components';

export const CardSocial = styled.div`
  transition: all 0.125s ease-in-out;
  width: 100%;
  a {
    color: #2c303b;
    padding-right: 1rem;
    &:hover {
      opacity: 0.5;
      transition: all 0.125s ease-in-out;
    }
  }
`;
