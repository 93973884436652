import * as geohash from 'ngeohash';
import { City } from 'src/helpers/map.helpers';
import MarkerWithPopup from '../Marker';
import { DistrictData, NewDistrictData } from './DistrictLayer';

export interface FetchedTreeData {
  timestamp: string;
  trees: { [geohash: string]: TreeData };
}
export interface TreeData {
  geohash: string;
  hasWateringHole?: boolean;
  taxonLatin?: string;
  adoptedTreeName?: string;
  crownDiameter?: number;
}

export interface TreeDataWithWatering extends TreeData {
  wateringNeed: number;
}

export interface CityData {
  cityName: City;
  latitude: string;
  longitude: string;
  treeCount: string;
  districts: DistrictData[];
  districtName: string;
  boundingBox: [[string, string], [string, string]];
}

export interface FetchedCities {
  timestamp: string;
  cities: NewCityData[];
}

export interface NewCityData {
  cityName: City;
  geohash: string;
  treeCount: number;
  districts: NewDistrictData[];
  cityLevel: number;
  boundingBox: [string, string];
  customZoomCenter?: [number, number];
}

export interface LayerData {
  shownCities: NewCityData[];
  zoomIntoView: (coords: L.LatLngTuple, zoomLevel: number) => void;
}

const CityLayer = ({ shownCities, zoomIntoView }: LayerData) => {
  const defaultZoomLevel = 13;
  const zoomTo = (districts: NewDistrictData[], coordinates: L.LatLngTuple, zoomLevel: number) => {
    // if there is only one distric zoom to trees
    if (districts.length === 1) {
      zoomIntoView!(coordinates, 16);
    } else {
      zoomIntoView!(coordinates, zoomLevel);
    }
  };

  const cityMarkers = shownCities.map((city: NewCityData, i: number) => {
    const geographicPoint = geohash.decode(city.geohash);
    const coordinates = [geographicPoint.latitude, geographicPoint.longitude] as L.LatLngTuple;

    const zoom = city.districts.length === 1 ? 16 : defaultZoomLevel;
    const zoomCoords: L.LatLngTuple = city.customZoomCenter ?? coordinates;

    return (
      <MarkerWithPopup
        key={city.geohash}
        coords={coordinates}
        title={`${city.cityName} - ${city.treeCount}`}
        //title={city.cityName}
        //content={`${city.cityName} - ${city.treeCount}`}
        handleClick={() => zoomTo(city.districts, zoomCoords, zoom)}
      />
    );
  });

  return <>{cityMarkers}</>;
};

export default CityLayer;
