import styled from 'styled-components';

export const CardDescriptionTitle = styled.h2`
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: 130%;
  font-size: 1.25rem;
  opacity: 1;
  line-height: 150%;
  width: 90%;
  font-weight: bold;
`;
