import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { device } from 'src/styles/mediaQueries';
import { appColors, Color, colors, formColors } from 'src/styles/colors';

export const Container = styled.div`
  padding-bottom: 10px;

  p {
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media ${device.laptopMin} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const StyledInput = styled(TextField)`
  max-width: 65px;
  margin-right: 8px;
  background: ${appColors.white};
  border: 1px solid ${formColors.inputBorder}};
  border-radius: 4px;
  box-shadow: 0px 1px 3px 2px ${Color.GRAY_5};

  & .MuiInputBase-input,
  & .MuiTypography-colorTextSecondary {
    color: ${Color.GRAY_70};
  }
`;

export const StyledButton = styled(Button)`
  padding: 5px;
  margin: 2px;
  color: ${Color.GRAY_70};
  background: ${appColors.white};
  box-shadow: 0px 1px 3px 2px ${Color.GRAY_5};

  &:hover {
    border-color: ${colors.blue};
  }

  svg {
    color: ${colors.blue};
  }

  @media ${device.laptopMin} {
    margin: 0 2px;
  }

  &.MuiButton-root {
    text-transform: none;
  }
`;

export const StyledSubmit = styled(Button)`
  padding: 5px 20px;
`;

export const ErrorMessage = styled.div`
  margin-top: 10px;
  color: ${colors.error};
  display: flex;
`;
