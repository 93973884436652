import { matchPath } from 'react-router-dom';

export enum ROUTE {
  ABOUT = '/about',
  PROFILE = '/profile',
  STATS = '/stats',
  TREE_DETAIL = '/tree/:treeId',
  ADD_TREE = '/addTree',
}

export const API_ROUTES: { [route: string]: (...args: any) => string } = {
  tree: (treeId: string) => `/tree/${treeId}`,
  treeAdoption: (treeId: string) => `/tree/${treeId}/adopt`,
};

export const TOP_SIDEBAR_ROUTES = [ROUTE.ABOUT, ROUTE.PROFILE, ROUTE.STATS, ROUTE.ADD_TREE];
export const BOTTOM_SIDEBAR_ROUTES = [ROUTE.TREE_DETAIL];

export const AUTH_REDIRECT_URL = `${window.location.origin}${ROUTE.PROFILE}`;

export const isValidPath = (path: string) => {
  return Object.values(ROUTE).some((validPath) => matchPath(path, validPath));
};
