import React from 'react';
import { StyledNavLink, DeactivatedLink } from './ZalejmeLink.styles';

interface Props {
  destination: string;
  disabled?: boolean;
  children: React.ReactNode[] | React.ReactNode;
}

const ZalejmeLink: React.FC<Props> = ({ destination, disabled, children }) => {
  return disabled ? (
    <DeactivatedLink>{children}</DeactivatedLink>
  ) : (
    <StyledNavLink to={{ pathname: destination }}>{children}</StyledNavLink>
  );
};

export default ZalejmeLink;
